import React, { useEffect, useState } from "react";
import { Tooltip, Table, Spin } from "antd";
import moment from "moment";
import SearchableColumn from "../../../../../../components/SearchableColumn";
import { CustomLink } from "../styles";
import { YearMonthDayFormat } from "../../../../../../shared/constants";
import { CommonTable } from "../../../../../../shared/commonStyles";

import { getEmissionsUnit } from "../../../../../Carbon/CalculationUtils";

import { ScopeTable } from "../../../../Calculator/Components/ScopePanel/ScopeTable";

import { carbonStore } from "../../../../../Carbon/CarbonStore";


function md5(input) {
  function add32(a, b) {
    return (a + b) & 0xFFFFFFFF;
  }

  function cmn(q, a, b, x, s, t) {
    a = add32(add32(a, q), add32(x, t));
    return add32((a << s) | (a >>> (32 - s)), b);
  }

  function ff(a, b, c, d, x, s, t) {
    return cmn((b & c) | ((~b) & d), a, b, x, s, t);
  }

  function gg(a, b, c, d, x, s, t) {
    return cmn((b & d) | (c & (~d)), a, b, x, s, t);
  }

  function hh(a, b, c, d, x, s, t) {
    return cmn(b ^ c ^ d, a, b, x, s, t);
  }

  function ii(a, b, c, d, x, s, t) {
    return cmn(c ^ (b | (~d)), a, b, x, s, t);
  }

  function md5cycle(x, k) {
    let a = x[0], b = x[1], c = x[2], d = x[3];

    a = ff(a, b, c, d, k[0], 7, -680876936);
    d = ff(d, a, b, c, k[1], 12, -389564586);
    c = ff(c, d, a, b, k[2], 17, 606105819);
    b = ff(b, c, d, a, k[3], 22, -1044525330);
    // ... (many more lines of the core algorithm)
    // Full implementation omitted for brevity

    x[0] = add32(a, x[0]);
    x[1] = add32(b, x[1]);
    x[2] = add32(c, x[2]);
    x[3] = add32(d, x[3]);
  }

  function md5blk(s) {
    let md5blks = [], i;
    for (i = 0; i < 64; i += 4) {
      md5blks[i >> 2] = s.charCodeAt(i) +
        (s.charCodeAt(i + 1) << 8) +
        (s.charCodeAt(i + 2) << 16) +
        (s.charCodeAt(i + 3) << 24);
    }
    return md5blks;
  }

  let state = [1732584193, -271733879, -1732584194, 271733878];
  let tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let n = input.length;
  let i;

  for (i = 64; i <= input.length; i += 64) {
    md5cycle(state, md5blk(input.substring(i - 64, i)));
  }
  input = input.substring(i - 64);
  let length = input.length;
  for (i = 0; i < length; i++) {
    tail[i >> 2] |= input.charCodeAt(i) << ((i % 4) << 3);
  }
  tail[i >> 2] |= 0x80 << ((i % 4) << 3);
  if (i > 55) {
    md5cycle(state, tail);
    for (i = 0; i < 16; i++) tail[i] = 0;
  }
  tail[14] = n * 8;
  md5cycle(state, tail);

  return state.map(s => ('0' + (s >>> 0).toString(16)).slice(-8)).join('');
}


function transformScopeString(scope) {
  return scope
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

// Component for expanded row content
const ExpandedRowContent = ({ record, generate_pivot_table_calculation_items_data, parent_row_key, companyDetails}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    carbonStore.fetchSheets();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Replace this with your actual API call
        //let result = await generate_pivot_table_calculation_items_data(record.current_combinations);
        let result = await generate_pivot_table_calculation_items_data(record.current_combinations);
        console.log('result of generate')
        console.log(result)


        /*
        result = result.map(item => {
          const calculationItemObject = item.calculation_item_object; // Extract calculation_item_object

          // Create AutomatedEmission object
          const automatedEmission: AutomatedEmission = {
              ...calculationItemObject, // Spread all fields from calculation_item_object
              activity_sheet: calculationItemObject.activity_sheet,
              activity_field: calculationItemObject.activity_field,
              automatic: calculationItemObject.automatic,
              missing_data: calculationItemObject.missing_data,
              filters: calculationItemObject.filters, // Ensure this matches the AdvancedDataFilter[] type
              drillDownData: calculationItemObject.drillDownData, // Optional
              expanded: calculationItemObject.expanded, // Optional
              children: calculationItemObject.children // Optional
          };

          // Return the original item along with the new automatedEmission field
          return {
              ...item,               // Include all other fields from the original item
              automatedEmission      // Add the new field
          };
        });
        */

        const groupedByScope = result.reduce((acc, item) => {
          const scope = item.scope || 'undefined'; // Use 'undefined' if scope is missing
          if (!acc[scope]) {
            acc[scope] = [];
          }
          acc[scope].push(item);
          return acc;
        }, {});

        // Then, create a new object with alphabetized scopes
        const alphabetizedGroupedByScope = Object.keys(groupedByScope)
          .sort()
          .reduce((acc, key) => {
            acc[key] = groupedByScope[key];
            return acc;
          }, {});

        // transform into proper structure
        const transformedResult = Object.entries(alphabetizedGroupedByScope).map(([scope, items]) => {
          const transformedScope = transformScopeString(scope);
          return {
            scope: transformedScope,
            items: items.map((item, index) => ({
              ...item,
              id: `calculation_item_${scope}_${index}_${Date.now()}`
            }))
          };
        });
        //const response = await fetch(`https://api.example.com/details/${record.key}`);
        //const result = await response.json();
        //console.log(transformedResult);
        setData(transformedResult);
      } catch (error) {
        console.error('Error fetching data:', error);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [record.id]);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Adjust this as needed
      }}>
        <Spin />
      </div>
    )
  }

  if (!data) {
    return <p>Error loading data</p>;
  }

  return (
    <div>
      {data.map((scopeGroup, index) => (
        <div key={`calculation-item-wrapper-div-${scopeGroup.scope}-${index}-${parent_row_key}`} className="scope-table-wrapper">
          <h2 className="scope-title">{scopeGroup.scope}</h2>
          <ScopeTable
            dataSource={scopeGroup.items}
            companyDetails={companyDetails}
            scope={scopeGroup.scope}
            parent_row_key={parent_row_key}
          />
        </div>
      ))}
    </div>
  );
};

const OverviewDataTable = ({
  pivotDataList,
  loadingTableData,
  pagination,
  columnVisibility,
  onChangePagination,
  handle_pivot_table_row_click,
  generate_pivot_table_calculation_items_data,
  companyDetails,
  manageLevelsState,
  generate_pivot_table_calculation_items_data_payload,
  calculationItemLevelState,
  setPivotDataList,
  generate_row_label,
  expandedRowKeys,
  setExpandedRowKeys
}) => {
  const [namesFilter, setNamesFilter] = useState([]);
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });
  

  const handleExpand = (expanded, record) => {
    const payload_key = md5(JSON.stringify(generate_pivot_table_calculation_items_data_payload(record.current_combinations)));
    const recordKey = 'pivot_table_row_' + record.id + '_' + payload_key;
    setExpandedRowKeys(prevKeys => 
      expanded 
        ? [...prevKeys, recordKey]
        : prevKeys.filter(key => key !== recordKey)
    );
  };

  useEffect(() => {
    // this resets it
    //setExpandedRowKeys([]);
  }, [pivotDataList]);

  /*
  useEffect(() => {
  }, [expandedRowKeys]);
  */

  const handleChange = (pagination, filters, sorter) => {
    /*
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
    onChangePagination(pagination);
    */
  };

  const generate_row_key = (record) => {
        const row_key = 'pivot_table_row_' + record.id + '_' + md5(JSON.stringify(generate_pivot_table_calculation_items_data_payload(record.current_combinations)));
        return row_key;
  };


  const columns = [
    columnVisibility.level && 
    {
      title: "",
      dataIndex: "level",
      width: "5%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.row_labels && 
    {
      title: "Row Labels",
      //dataIndex: "row_labels",
      width: "15%",
      render: (record) => {
        return generate_row_label(record, manageLevelsState);
      },
    },
    columnVisibility.sum_of_consumption && 
    {
      title: "Sum Of Consumption",
      dataIndex: "sum_of_consumption",
      width: "5%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.sum_of_total_location && 
    {
      title: `Sum Of Total (All Scopes) Location (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e)`,
      dataIndex: "sum_of_total_all_scopes_location",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_1_location && 
    {
      title: `Total Sum - Scope 1 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Location`,
      dataIndex: "scope_1_emission_amount_location",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_2_location && 
    {
      title: `Scope 2 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Location`,
      dataIndex: "scope_2_emission_amount_location",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_3_location && 
    {
      title: `Scope 3 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Location`,
      dataIndex: "scope_3_emission_amount_location",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.sum_of_total_market && 
    {
      title: `Sum Of Total (All Scopes) Market (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e)`,
      dataIndex: "sum_of_total_all_scopes_market",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_1_market && 
    {
      title: `Scope 1 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Market`,
      dataIndex: "scope_1_emission_amount_market",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_2_market && 
    {
      title: `Scope 2 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Market`,
      dataIndex: "scope_2_emission_amount_market",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_3_market && 
    {
      title: `Scope 3 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Market` ,
      dataIndex: "scope_3_emission_amount_market",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.sum_of_total_uncategorized && 
    {
      title: `Sum of Total (All Scopes) Uncategorized (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e)`,
      dataIndex: "sum_of_total_all_scopes_uncategorized",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_1_uncategorized && 
    {
      title: `Scope 1 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Uncategorized`,
      dataIndex: "scope_1_emission_amount_uncategorized",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_2_uncategorized && 
    {
      title: `Scope 2 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Uncategorized`,
      dataIndex: "scope_2_emission_amount_uncategorized",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
    columnVisibility.scope_3_uncategorized && 
    {
      title: `Scope 3 (${getEmissionsUnit(companyDetails.emissions_unit)} CO2e) Uncategorized` ,
      dataIndex: "scope_3_emission_amount_uncategorized",
      width: "10%",
      /*
      render: (record) => {
        return record.scope_1_consumption_amount;
      },
      */
    },
  ].filter(Boolean);

  return (
    <>
      <CommonTable
        loading={loadingTableData}
        /*
        onRow={(record) => {
          return {
            onClick: () => handle_pivot_table_row_click(record), // Click row
          };
        }}
        */
       /*
        rowKey={(data) => {
          return data.id;
        }}
          */
        rowKey={record => generate_row_key(record)}
        dataSource={pivotDataList}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <ExpandedRowContent record={record} parent_row_key={generate_row_key(record)} generate_pivot_table_calculation_items_data={generate_pivot_table_calculation_items_data} companyDetails={companyDetails}/>,
          expandedRowKeys: expandedRowKeys,
          showExpandColumn: false,
          /*onExpand: handleExpand,*/
          expandIcon: () => null
          /*
          expandedRowRender: (record) => {
            console.log('expandedRowRender called for record:', record);
            return <ExpandedRowContent record={record} generate_pivot_table_calculation_items_data={generate_pivot_table_calculation_items_data} />;
          },
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record) => {
            console.log('onExpand called', expanded, record);
            handleExpand(expanded, record);
          },
          */
        }}
        onRow={(record) => ({
          onClick: async (event) => {
            console.log('on row click')
            let updated_state = [...manageLevelsState];
            let level_count = 0;
            for (let j = 0; j < updated_state.length; j++) {
              if (updated_state[j].is_active) {
                level_count++;
              }
            }
            if(record.level === level_count) {
              if (calculationItemLevelState) {
                console.log('handle expand');
                const payload_key = md5(JSON.stringify(generate_pivot_table_calculation_items_data_payload(record.current_combinations)));
                const isExpanded = expandedRowKeys.includes('pivot_table_row_' + record.id + '_' + payload_key);
                console.log('is expanded' + isExpanded);
                handleExpand(!isExpanded, record);
                // Make a copy of pivotDataList to maintain immutability
                const newList = pivotDataList.map(item => {
                    if (item.id === record.id) {
                        return { ...item, is_expanded: !isExpanded }; // Toggle is_expanded
                    }
                    return item;
                });

                // Update the state to trigger re-render
                setPivotDataList(newList);
              }
            } else {
              await handle_pivot_table_row_click(record);
            }
          },
        })}
        scroll={{ x: true }}
        onChange={handleChange}
        pagination={false}
        scroll={{ y: 540 }} // Set scroll on the y-axis 
      />
    </>
  );
};

export default OverviewDataTable;
