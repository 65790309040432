import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

export const dataIntegration = async () => {
  const response = await API.graphql({
    query: queries["dataIntegration_job"],
    variables: { request_type: "EHS_REPORT" },
  });
  const resp = response["data"]["dataIntegration_job"];
  const responseMatch = resp?.match(/job_id=([^;\n]+)/);
  const responseName = responseMatch
    ? responseMatch[1]?.replace(/}/g, "")
    : null;
  return responseName;
};

export const aiIntegration = async (request_payload) => {
  const response = await API.graphql({
    query: queries["aiOperation_job"],
    variables: {
      request_type: "AI_LLM_OPERATION",
      request_payload: JSON.stringify(request_payload),
    },
  });
  const resp = response["data"]["aiOperation_job"];
  const responseMatch = resp?.match(/job_id=([^;\n]+)/);
  const responseName = responseMatch
    ? responseMatch[1]?.replace(/}/g, "")
    : null;

  console.log(response, "response");
  return response;
};
