import React, { useState, useEffect, useRef } from "react";
import "./ReadinessScoreComponent.scss";
import SparkleIcon from "./SparkleIcon";
import { RightOutlined } from "@ant-design/icons";
import { Progress, ProgressProps, Spin } from "antd";
import { AiValidatorData, ReadinessScoreItem } from "./Calculator"; // Assuming ReadinessScoreItem is exported from Calculator

interface ReadinessScoreProps {
  onClick: () => void; // Define the type of onClick as a prop
  data: AiValidatorData | null;
}

const twoColors: ProgressProps["strokeColor"] = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

interface ScoreCardProps {
  item: ReadinessScoreItem;
  height?: number | string;
}

export const ScoreCard: React.FC<ScoreCardProps> = ({ item, height }) => {
  return (
    <div className="scorecard-container" style={{ height }}>
      <h3 style={{ color: "white" }}>{item.name}</h3>
      <Progress
        percent={
          typeof item.value === "boolean"
            ? item.value
              ? 100
              : 0
            : typeof item.value === "number" && item.value > 0
            ? item.value
            : 0
        }
        strokeColor={twoColors}
        format={(percent) => <span style={{ color: "white" }}>{percent}%</span>}
      />
      <p>
        <strong>Description:</strong> {item.description}
      </p>
      <p>
        <strong>Reasoning:</strong> {item.reasoning}
      </p>
    </div>
  );
};

export const ReadinessScoreComponent: React.FC<ReadinessScoreProps> = ({
  onClick,
  data,
}) => {
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const scoreCardRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (open && data) {
      // Calculate the maximum height among all scorecards

      const heights = Array.isArray(scoreCardRefs.current)
        ? scoreCardRefs.current.map((card) => (card ? card.offsetHeight : 0))
        : [];

      const maxHeight = Math.max(...heights);
      setMaxHeight(maxHeight);
    }
  }, [open, data]);

  return (
    <div
      className={`parent-container ${open ? "open" : "closed"} ${
        open && data ? "loaded" : ""
      }`}
    >
      <div
        className="header"
        onClick={() => {
          setOpen(!open);
          onClick();
        }}
      >
        <RightOutlined />
        <SparkleIcon />
        <p>Analyze Readiness Score</p>
      </div>

      {open && (
        <div className={`content ${!data ? "loading-state" : ""}`}>
          {!data ? (
            <div className="loading" style={{ textAlign: "center" }}>
              <Spin />
              <p>Analyzing...</p>
            </div>
          ) : (
            Array.isArray(data.items) &&
            data.items.map((item, index) => (
              <div
                ref={(el) => {
                  if (scoreCardRefs.current) {
                    scoreCardRefs.current[index] = el;
                  }
                }}
                key={item.key}
              >
                <ScoreCard item={item} height={maxHeight ?? "auto"} />
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};
