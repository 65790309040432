import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { encodeUrlName } from "../../../../../shared/commonFunctions";

import { CommonTable, PageWrap, StyledButton } from "../../../../../shared/commonStyles";

import { getLinkDataSheet, getLinkDataSheetSimple } from "../../../../Carbon/CalculationUtils";
import { carbonStore } from "../../../../Carbon/CarbonStore";
import { calculatorStore } from "../../../../Carbon/CalculatorStore";

import styles from "../../Calculator.module.scss";

import { InfoAndNotesDrawer } from "../../../../carbonPage/Calculator/Components/InfoAndNotesDrawer";

import {
  EyeOutlined,
} from "@ant-design/icons";

interface ScopeTableProps {
    // dataColumns: any[];
    dataSource: any[];
    companyDetails: any;
    scope: String;
    parent_row_key: String;
}

import {
  Button,
} from "antd";

export const ScopeTable: React.FC<ScopeTableProps> = ({
    // dataColumns,
    dataSource,
    companyDetails,
    scope,
    parent_row_key
}) => {

    const [infoDrawerVisible, setInfoDrawerVisible] = useState(false);
    const [drawerData, setDrawerData] = useState({
      record: {} as Emission,
      drillDownRecord: {} as DrillDownRecord,
    });

    const handleInfoDrawer = (record: Emission, drillDownRecord: any) => {
      setInfoDrawerVisible(true);
      setDrawerData({ record, drillDownRecord });
    };

    // {
    //     "_id": {
    //         "$oid": "65d314f34cee246dc2716d80"
    //     },
    //     "group": "Fix Price",
    //     "scope": "scope_2",
    //     "shop_name": "Fix Price",
    //     "indicatorType": "default",
    //     "year": 2023,
    //     "emission": 319.0852223,
    //     "consumption_amount": 650
    // },

    const columns = [
        { 
            title: 'Name', 
            //dataIndex: 'group',
            render: (record: any) => {
                let d = record.factor_data.name ? record.factor_data.name : "NA";
                return <p>{d}</p>
            }
        },
        { 
            title: 'Region', 
            //dataIndex: 'region',
            render: (record: any) => {
                let d = record.region_name ? record.region_name : "NA";
                return <p>{d}</p>
            }
        },
        { 
            title: 'Custom Name', 
            //dataIndex: 'custom_name' 
            render: (record: any) => {
                let d = record.custom_name ? record.custom_name : "NA";
                return <p>{d}</p>
            }
        },
        { 
            title: 'Consumption Amount', 
            render: (record: any) => {
                // can change this logic later but it should use metadata_drilldown
                return <p>{(record.metadata_drilldown.consumption_amount).toFixed(companyDetails.decimal_places_number)} kWh</p>;
            }
        },
        { 
            title: 'co2e_factor', 
            //dataIndex: 'co2e_factor',
            render: (record: any) => {
                let d = record.factor ? record.factor : "NA";
                return <p>{d}</p>
            }
        },
        { 
            title: 'Emission', 
            render: (record: any) => {
                return <p>{(record.emission*companyDetails.factor).toFixed(companyDetails.decimal_places_number)}</p>;
            }
        },
        { 
            title: 'Indicator Type', 
            render: (record: any) => {
                return <p>{(record.indicatorType)}</p>;
            }
        },
        { 
            title: 'Action', 
            render: (record: any) => {
                return (

                    <div>

                        <Button
                            className={styles.btnIcon}
                            onClick={() => {
                                const emissionRecord = record.calculation_item_object as AutomatedEmission;
                                console.log('emission record')
                                console.log(emissionRecord);
                                calculatorStore.changeSelectedIdentifiers([record.group]);
                                //const currentData = {};
                                let currentData: DrillDownRecord | undefined = undefined;

                                handleInfoDrawer(emissionRecord!, currentData)
                            }}
                        >
                            <EyeOutlined />
                        </Button>
                        <StyledButton type="primary">
                        <Link
                        //to={`/data/data-sheets/${encodeUrlName(record.activity_sheet)}/documents`}
                        to={`/data/data-sheets/${encodeUrlName(record.activity_sheet)}/${encodeUrlName(record.sheet_name)}/documents`}
                        //   state={{
                        //     calcId: data._id?.$oid,
                        //     ...((data as CalculationInGroup).groupId && {
                        //       groupId: (data as CalculationInGroup).groupId,
                        //     }),
                        //   }}
                        onClick={() => {
                            console.log('calculation item object');
                            console.log(record.calculation_item_object)
                            const automated_emission = record.calculation_item_object as AutomatedEmission;

                            // Ensure drillDownRecord adheres to the DrillDownRecord interface or null
                            let drillDownRecord: DrillDownRecord | undefined = undefined;

                            if(record.calculation_item_object['drillDownData']) {
                                // Check if 'metadata_drilldown' is an array and assign drillDownRecord accordingly
                                drillDownRecord = Array.isArray(automated_emission['drillDownData'])
                                ? record.calculation_item_object['drillDownData'][0] as DrillDownRecord // First element of the array
                                : record.calculation_item_object['drillDownData'] as DrillDownRecord;    // Object itself


                            } 
                            /*
                            // Check if 'metadata_drilldown' is an array and assign drillDownRecord accordingly
                            drillDownRecord = Array.isArray(record.calculation_item_object['metadata_drilldown'])
                            ? record.calculation_item_object['metadata_drilldown'][0] as DrillDownRecord // First element of the array
                            : record.calculation_item_object['metadata_drilldown'] as DrillDownRecord;    // Object itself
                            */
                            
                            /*
                            const customSheetSchema: SheetSchema = {
                                added: true,
                                data_type: "string",
                                display_name: "Site ID",
                                entity_name: "site_id",
                                input_type: "string",
                                metadata_entity_name: "site_id",
                                schemasKey: "3244547e-67ac-4d3d-a03c-1932cb5ae72c", // Added as a custom field
                                status: false,
                                valuesType: "columnColumn", // Added as a custom field
                                expressionProperties: {
                                    column1: "salary",
                                    column2: "bonus",
                                    numericalValue: 1000,
                                    operator: "+"
                                },
                                required: true,
                                visible: true,
                                entityrecord_name: "employee_record"
                            }
                                */

                            /*
                            const dummySheetSchema: SheetSchema = {
                                added: true,
                                data_type: "string",
                                display_name: "Employee Name",
                                entity_name: "employee",
                                entityrecord_name: "employee_record",
                                input_type: "text",
                                metadata_entity_name: "employee_metadata",
                                required: true,
                                visible: true,
                                unit_id: "emp001",
                                options: ["Option 1", "Option 2", "Option 3"],
                                is_checkbox_group: false,
                                convertable_units: ["meters", "feet"],
                                isExpression: true,
                                isIdentifier: false,
                                status: "active",
                                expressionProperties: {
                                    column1: "salary",
                                    column2: "bonus",
                                    numericalValue: 1000,
                                    operator: "+"
                                },
                            };



                            const dummyDataSheet: DataSheet = {
                                _id: { $oid: "60c72b2f9b1d4f4d4c2a2eae" }, // Example ObjectId
                                archive: false,
                                create_date: { $date: "2024-01-01T00:00:00Z" }, // Example date in ISO format
                                description: "This is a sample data sheet for testing purposes.",
                                group: "Sample Group",
                                metadata_sheet_id: "sample-metadata-sheet-id",
                                permissions: {
                                    group_name_id: ["group1"], // Example group IDs
                                    startdate: "2024-01-01",
                                    enddate: "2024-12-31"
                                },
                                sheet_name: "Sample Data Sheet",
                                sheet_schema: [dummySheetSchema],
                                sheet_type: "regular",
                                update_date: { $date: "2024-01-02T00:00:00Z" }, // Example date in ISO format
                                username: "sampleUser",
                                enable_change_history: true,
                                enable_comments: false,
                                data_survey_id: "sample-data-survey-id",
                                is_reference_data: true,
                            };
                            */


                            //const automatedEmission = record.calculation_item_object as AutomatedEmission;
                            localStorage.setItem(
                                "filters",
                                JSON.stringify(
                                getLinkDataSheetSimple(
                                    automated_emission,
                                    record.sheet_name,
                                    drillDownRecord
                                ).filtersArray
                                )
                            );
                            const arr_identifiers = [];
                            arr_identifiers.push(record.group)
                            localStorage.setItem(
                                "identifiersValues",JSON.stringify(arr_identifiers)
                            );
                            //console.log('ident values')
                            //console.log(localStorage.getItem('identifiersValues'))
                            console.log('filters')
                            console.log(localStorage.getItem('filters'))
                        }}
                        target="_blank"
                        >
                        Open
                        </Link>
                    </StyledButton>
                    </div>
                )
            }
        }
    ];

    return (
        <div>

         <InfoAndNotesDrawer
          isCalculationLocked={true}
          typeDrawer={"info"}
          calculationEntry={drawerData.record as Emission}
          drillDownRecord={drawerData.drillDownRecord}
          visible={infoDrawerVisible}
          onClose={() => setInfoDrawerVisible(false)}
          calculationEntryType={CalculationEntryType.EMISSIONS}
        />

        <CommonTable
            rowKey={(data: { id: string; }) => {
                /*console.log('data id');
                console.log(data.id);
                */
                return data.id;
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size="small"
        />
        </div>

    )

};