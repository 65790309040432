import React, { useEffect, useRef, useState } from "react";
import { useBoolean } from "ahooks";
import { StyledButton, TableCommonMenu } from "../../../../shared/commonStyles";
import { EditAutomatedEmissionsModal } from "./EditAutomatedEmissionsModal";
import { Button, notification, Menu, Dropdown, Badge, Spin } from "antd";
import {
  EyeOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  UndoOutlined,
  UnorderedListOutlined,
  RedoOutlined,
  EditOutlined,
  FileDoneOutlined,
  ExceptionOutlined,
  AppstoreOutlined,
  SyncOutlined,
  EnvironmentOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { InfoAndNotesDrawer } from "./InfoAndNotesDrawer";
import { CalculatorEntryHistoryDrawer } from "./CalculatorEntryHistoryDrawer";
import { CalculatorEntryHistoryButtons } from "./CalculatorEntryHistoryButtons";
import styles from "./TableRowBtns.module.scss";
import { AddOffset } from "./AddOffset";
import { EditManualEmissionsModal } from "./EditManualEmissionsModal";
import { CategoryModal } from "./CategoryModal";
import { IndicatorModal } from "./IndicatorModal";
import { calculatorStore } from "../../../Carbon/CalculatorStore";
import { RecModal } from "./RecModal";
import { EditEmissionFactorModal } from "./EditEmissionFactorModal/EditEmissionFactorModal";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import EditAddEditEntityModal from "./EditAddEditEntityModal/EditAddEditEntityModal";
import SparkleIcon from "../SparkleIcon";
import { AiValidatorData, validateGhgCalculation } from "../Calculator";
import { ScoreCard } from "../ReadinessScoreComponent";

interface TableActionsButtonsProps {
  data: Emission | Offset;
  identifiers?: string[];
  isDeletedItems?: boolean;
  isCalculationLocked?: boolean;
  onDelete: () => void;
  onDeleteFromEmissionsData?: () => void;
  onClickUndo: () => void;
  onEditOffset?: (data: Offset) => void;
  onEditEmission?: (data: Emission) => void;
  onViewHistory?: (historyData: CalculatorEntryHistory) => void;
  onRestoreHistory?: (historyData: CalculatorEntryHistory) => void;
  onRestoreElement?: () => void;
  onGoCurrent?: () => void;
  onMarkAsEstimated?: (data: Emission) => void;
  viewingHistory?: boolean;
  onViewCurrentVersion?: () => void;
}

const TableActionsButtons = ({
  data,
  identifiers,
  isDeletedItems = false,
  isCalculationLocked,
  onDelete,
  onClickUndo,
  onEditOffset = ({}: Offset) => {},
  onEditEmission = ({}: Emission) => {},
  onDeleteFromEmissionsData = () => {},
  onViewHistory = () => {},
  onRestoreHistory = () => {},
  onRestoreElement = () => {},
  onGoCurrent = () => {},
  onMarkAsEstimated = ({}: Emission) => {},
  onViewCurrentVersion,
  viewingHistory = false,
}: TableActionsButtonsProps) => {
  const { checkRolesPermission } = useCheckRolesPermissions();
  const isOffset = data.scope === ScopeName.OFFSETS;
  const [typeDrawer, setTypeDrawer] = useState<"info" | "notes">("info");
  const [showEditEntity, setShowEditEntity] = useState(false);
  const [requestPending, setRequestPending] = useState(true);
  const [
    drawerHistoryVisible,
    { setTrue: setDrawerHistoryVisible, setFalse: setDrawerHistoryHidden },
  ] = useBoolean(false);
  const [areNotesVisible, { setTrue: showNotes, setFalse: hideNotes }] =
    useBoolean(false);
  const [
    isEditModalVisible,
    { setTrue: showEditModal, setFalse: hideEditModal },
  ] = useBoolean(false);
  const [
    isIndicatorModalVisible,
    { setTrue: showIndicatorModal, setFalse: hideIndicatorModal },
  ] = useBoolean(false);
  const [
    isCategoryModalVisible,
    { setTrue: showCategoryModal, setFalse: hideCategoryModal },
  ] = useBoolean(false);
  const [isRECModalVisible, { setTrue: showRECModal, setFalse: hideRECModal }] =
    useBoolean(false);
  const [readinessScoreVisible, setReadinessScoreVisible] = useState(false);
  const [isHistoryData, setIsHistoryData] = useState(false);
  const [popoverData, setPopoverData] = useState<CalculatorEntryHistory>(
    {} as CalculatorEntryHistory
  );
  const [aiValidatorClicked, setAiValidatorClicked] = useState(false);
  const [aiValidatorData, setAiValidatorData] =
    useState<AiValidatorData | null>(null);
  const scoreCardRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (data.historyInfo) {
      setPopoverData(data.historyInfo);
    }
  }, [data]);

  const emission = { ...data } as Emission;

  const emissionTotal = emission?.override
    ? emission?.total_emission?.co2e_total
    : emission?.factor_data?.factor * emission?.consumption_amount;

  const handleUndo = () => {
    setRequestPending(false);
    notification.close("myNotification");
    onClickUndo();
  };
  const handleDelayedRequest = () => {
    onDelete();
  };
  const handleInfoDrawer = () => {
    showNotes();
    setTypeDrawer("info");
  };
  const handleNotesDrawer = () => {
    showNotes();
    setTypeDrawer("notes");
  };
  const handleMarkAsEstimated = (estimated: boolean) => {
    const newData = { ...data, estimated };

    data && onMarkAsEstimated(newData as Emission);
  };

  const handleView = (history: CalculatorEntryHistory) => {
    onViewHistory(history);
    setIsHistoryData(true);
    setDrawerHistoryHidden();
    setPopoverData(history);
  };

  const handleCurrent = () => {
    onGoCurrent();
    setIsHistoryData(false);
  };

  const handleRestoreHistory = () => {
    onRestoreHistory(popoverData);
    setIsHistoryData(false);
  };

  const openNotification = () => {
    const key = "myNotification";
    const btn = (
      <StyledButton
        onClick={() => {
          notification.close(key);
          handleUndo();
        }}
      >
        <UndoOutlined />
        <span>Undo</span>
      </StyledButton>
    );
    notification.open({
      message: (
        <div className={styles.successBox}>
          <div className={styles.successText}>Success</div>
          <div className={styles.successDeleteText}>
            Emission was deleted successfully
          </div>
          {btn}
        </div>
      ),
      key,
      className: styles.successDeleteMessage,
      placement: "top",
      duration: 7,
      onClose: () => {
        if (requestPending) {
          handleDelayedRequest();
        }
      },
    });
  };

  const handleDelete = () => {
    onDeleteFromEmissionsData();
    openNotification();
  };

  const handleAssignEntity = (newEntity: string) => {
    setShowEditEntity(false);

    const newData = {
      ...emission,
      metadata_drilldown: [
        {
          group: newEntity,
          unattributed_emission: emission.unattributed_emission,
          emission: emission.emission,
          consumption_amount: emission.consumption_amount,
        },
      ],
    };

    onEditEmission(newData as Emission);
  };

  const handleConfirm = (offset: Offset) => {
    onEditOffset(offset);
    hideEditModal();
  };

  const handleChangeIndicatorValue = (value: "default" | "m" | "l") => {
    hideIndicatorModal();
    if (calculatorStore.calculationId.length) {
      calculatorStore.editCalculationEntry({ ...data, indicatorType: value });
    } else
      calculatorStore.editCalculationStepperEntry({
        ...data,
        indicatorType: value,
      });
  };
  const handleChangeCategoryValue = (value: string) => {
    hideCategoryModal();
    if (calculatorStore.calculationId.length) {
      calculatorStore.editCalculationEntry({ ...data, category: value });
    } else
      calculatorStore.editCalculationStepperEntry({
        ...data,
        category: value,
      });
  };

  const isTypeFactor = (
    factorData: Factor | CustomManualFactor
  ): factorData is Factor => {
    return "_id" in factorData;
  };

  const handleChangeRECAmount = (recAmount: number, recPercent: number) => {
    hideRECModal();
    if (calculatorStore.calculationId.length) {
      calculatorStore.editCalculationEntry({ ...data, recAmount, recPercent });
    } else
      calculatorStore.editCalculationStepperEntry({
        ...data,
        recAmount,
        recPercent,
      });
  };

  const ReadinessScoreDrawer = () => {
    return (
      <div
        className={styles.readinessScoreContainer}
        onClick={(e) => {
          setReadinessScoreVisible(false);
        }}
      >
        <div
          className={styles.readinessScoreContainerDrawer}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.header}>
            <CloseOutlined onClick={() => setReadinessScoreVisible(false)} />
            <strong>Readiness Score</strong>
          </div>
          <div
            className={styles.scrollContainer}
            style={{
              justifyContent: !aiValidatorData ? "center" : "flex-start",
            }}
          >
            {!aiValidatorData ? (
              <div className={styles.loading} style={{ textAlign: "center" }}>
                <Spin />
                <p>Analyzing... This may take a minute.</p>
              </div>
            ) : (
              <>
                {aiValidatorData.items.map((item, index) => (
                  <div
                    ref={(el) => (scoreCardRefs.current[index] = el)}
                    key={item.key}
                    className={styles.scoreCardContainer}
                  >
                    <ScoreCard item={item} height={"auto"} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const actions = {
    calculator: {
      emissions: (
        <TableCommonMenu>
          {!checkRolesPermission(["role:elevateduser"]) &&
            !viewingHistory &&
            ((!(data as Emission).manual && (
              <Menu.Item key={"edit"} className={styles.actionsButton}>
                <EditAutomatedEmissionsModal
                  disabled={isCalculationLocked}
                  data={emission as AutomatedEmission}
                  editEmission={onEditEmission}
                />
              </Menu.Item>
            )) ||
              ((data as Emission).manual &&
                !viewingHistory &&
                isTypeFactor((data as Emission).factor_data) && (
                  <Menu.Item key={"edit"} className={styles.actionsButton}>
                    <EditEmissionFactorModal
                      disabled={isCalculationLocked}
                      data={emission as Emission}
                      editEmission={onEditEmission}
                    />
                  </Menu.Item>
                )) ||
              (isTypeFactor((data as Emission).factor_data) &&
                !viewingHistory && (
                  <Menu.Item key={"edit"} className={styles.actionsButton}>
                    <EditManualEmissionsModal
                      disabled={isCalculationLocked}
                      data={emission as Emission}
                      editEmission={onEditEmission}
                    />
                  </Menu.Item>
                )))}
          <Menu.Item key={"notes"}>
            <Button onClick={handleNotesDrawer}>
              <UnorderedListOutlined />
              View notes
            </Button>
          </Menu.Item>

          {!checkRolesPermission(["role:elevateduser"]) && !viewingHistory && (
            <Menu.Item key={"isEstimated"} className={styles.actionsButton}>
              {(data as Emission).estimated ? (
                <Button
                  onClick={() => handleMarkAsEstimated(false)}
                  disabled={isCalculationLocked}
                >
                  <FileDoneOutlined />
                  Mark as final
                </Button>
              ) : (
                !viewingHistory && (
                  <Button
                    onClick={() => handleMarkAsEstimated(true)}
                    disabled={isCalculationLocked}
                  >
                    <ExceptionOutlined />
                    Mark as estimated
                  </Button>
                )
              )}
            </Menu.Item>
          )}

          {!checkRolesPermission(["role:elevateduser"]) &&
            !isCalculationLocked &&
            !viewingHistory &&
            emission.manual && (
              <Menu.Item key={"assignEntity"} className={styles.actionsButton}>
                <Button
                  onClick={() => {
                    setShowEditEntity(true);
                  }}
                >
                  <EnvironmentOutlined />
                  Assign entity
                </Button>
              </Menu.Item>
            )}
          {!checkRolesPermission(["role:elevateduser"]) &&
            !viewingHistory &&
            data.scope === ScopeName.SCOPE_2 && (
              <Menu.Item key="indicator">
                <Button onClick={showIndicatorModal}>
                  <AppstoreOutlined />
                  Change indicator type
                </Button>
              </Menu.Item>
            )}
          {!checkRolesPermission(["role:elevateduser"]) &&
            !viewingHistory &&
            data.scope === ScopeName.SCOPE_2 && (
              <Menu.Item key="recAmount">
                <Button onClick={showRECModal}>
                  <SyncOutlined />
                  Change amount of RECs
                </Button>
              </Menu.Item>
            )}

          {!checkRolesPermission(["role:elevateduser"]) &&
            !viewingHistory &&
            data.scope === ScopeName.SCOPE_3 && (
              <Menu.Item key="recAmount">
                <Button onClick={showCategoryModal}>
                  <AppstoreOutlined />
                  Select scope 3 category
                </Button>
              </Menu.Item>
            )}
          {!!calculatorStore.calculationId && !viewingHistory && (
            <Menu.Item key="history">
              <Button onClick={setDrawerHistoryVisible}>
                <UndoOutlined />
                History
              </Button>
              <CalculatorEntryHistoryDrawer
                data={data as Emission}
                isVisible={drawerHistoryVisible}
                hideDrawer={setDrawerHistoryHidden}
                onView={handleView}
              />
            </Menu.Item>
          )}

          {viewingHistory && (
            <Menu.Item key="return-current-version">
              <Button onClick={onViewCurrentVersion}>
                <UndoOutlined />
                Go to Current Version
              </Button>
            </Menu.Item>
          )}

          {!checkRolesPermission(["role:elevateduser"]) && !viewingHistory && (
            <Menu.Item key={"analyze-score"}>
              <Button
                onClick={async () => {
                  setReadinessScoreVisible(true);
                  if (!aiValidatorClicked && data) {
                    setAiValidatorClicked(true);
                    let result = await validateGhgCalculation(data, true);
                    console.log(result, "result success return");
                    setAiValidatorData(result);
                    setAiValidatorClicked(true);
                  }
                }}
              >
                <div className={styles.sparkleContainer}>
                  <SparkleIcon />
                </div>
                Analyze Readiness Score
              </Button>
            </Menu.Item>
          )}

          {!checkRolesPermission(["role:elevateduser"]) &&
            !viewingHistory &&
            !isCalculationLocked && (
              <Menu.Item key={"delete"} className={styles.actionsButton}>
                <Button onClick={handleDelete}>
                  <DeleteOutlined />
                  Delete
                </Button>
              </Menu.Item>
            )}
        </TableCommonMenu>
      ),
      offsets: (
        <TableCommonMenu>
          <Menu.Item key={"notes"}>
            <Button onClick={handleNotesDrawer}>
              <UnorderedListOutlined />
              View notes
            </Button>
          </Menu.Item>

          {!checkRolesPermission(["role:elevateduser"]) && (
            <Menu.Item key={"edit"} className={styles.actionsButton}>
              <Button onClick={showEditModal} disabled={isCalculationLocked}>
                <EditOutlined />
                Edit
              </Button>
              <AddOffset
                data={data as Offset}
                visible={isEditModalVisible}
                modalName="Edit offset"
                onCancel={hideEditModal}
                onConfirm={handleConfirm}
              />
            </Menu.Item>
          )}

          <Menu.Item key={"history"}>
            <Button onClick={setDrawerHistoryVisible}>
              <UndoOutlined />
              History
            </Button>
            <CalculatorEntryHistoryDrawer
              data={data as Offset}
              isVisible={drawerHistoryVisible}
              hideDrawer={setDrawerHistoryHidden}
              onView={handleView}
            />
          </Menu.Item>

          {!checkRolesPermission(["role:elevateduser"]) && (
            <Menu.Item key={"delete"} className={styles.actionsButton}>
              <Button onClick={handleDelete} disabled={isCalculationLocked}>
                <DeleteOutlined />
                Delete
              </Button>
            </Menu.Item>
          )}
        </TableCommonMenu>
      ),
    },
    deletedItems: {
      emissions: (
        <TableCommonMenu>
          <Menu.Item>
            <Button onClick={handleNotesDrawer}>
              <UnorderedListOutlined />
              View notes
            </Button>
          </Menu.Item>

          <Menu.Item key="history">
            <Button onClick={setDrawerHistoryVisible}>
              <UndoOutlined />
              History
            </Button>
            <CalculatorEntryHistoryDrawer
              data={data as Emission}
              isVisible={drawerHistoryVisible}
              hideDrawer={setDrawerHistoryHidden}
              onView={handleView}
            />
          </Menu.Item>

          {!checkRolesPermission(["role:elevateduser"]) && (
            <>
              <Menu.Item className={styles.actionsButton}>
                <Button
                  onClick={onRestoreElement}
                  disabled={isCalculationLocked}
                >
                  <RedoOutlined rotate={-90} />
                  Restore
                </Button>
              </Menu.Item>

              <Menu.Item className={styles.actionsButton}>
                <Button onClick={handleDelete} disabled={isCalculationLocked}>
                  <DeleteOutlined />
                  Delete
                </Button>
              </Menu.Item>
            </>
          )}
        </TableCommonMenu>
      ),
      offsets: (
        <TableCommonMenu>
          <Menu.Item>
            <Button onClick={handleNotesDrawer}>
              <UnorderedListOutlined />
              View notes
            </Button>
          </Menu.Item>

          <Menu.Item key="history">
            <Button onClick={setDrawerHistoryVisible}>
              <UndoOutlined />
              History
            </Button>
            <CalculatorEntryHistoryDrawer
              data={data as Offset}
              isVisible={drawerHistoryVisible}
              hideDrawer={setDrawerHistoryHidden}
              onView={handleView}
            />
          </Menu.Item>

          {!checkRolesPermission(["role:elevateduser"]) && (
            <>
              <Menu.Item className={styles.actionsButton}>
                <Button
                  onClick={onRestoreElement}
                  disabled={isCalculationLocked}
                >
                  <RedoOutlined rotate={-90} />
                  Restore
                </Button>
              </Menu.Item>

              <Menu.Item className={styles.actionsButton}>
                <Button onClick={handleDelete} disabled={isCalculationLocked}>
                  <DeleteOutlined />
                  Delete
                </Button>
              </Menu.Item>
            </>
          )}
        </TableCommonMenu>
      ),
    },
  };

  const menu = () => {
    if (!isDeletedItems) {
      return !isOffset
        ? actions.calculator.emissions
        : actions.calculator.offsets;
    } else {
      return !isOffset
        ? actions.deletedItems.emissions
        : actions.deletedItems.offsets;
    }
  };

  return (
    <>
      {readinessScoreVisible && <ReadinessScoreDrawer />}

      <EditAddEditEntityModal
        visible={showEditEntity}
        onCancel={() => setShowEditEntity(false)}
        entities={identifiers}
        selectedEntity={
          emission.metadata_drilldown &&
          emission.metadata_drilldown[0] &&
          emission.metadata_drilldown[0].group
            ? emission.metadata_drilldown[0].group
            : ""
        }
        handleAssignEntity={handleAssignEntity}
      />
      <div className={styles.btnIconsWrap}>
        <Button className={styles.btnIcon} onClick={handleInfoDrawer}>
          <EyeOutlined />
        </Button>
        {data.historyInfo ? (
          <CalculatorEntryHistoryButtons
            isDeleted={isDeletedItems}
            popoverData={popoverData}
            onRestore={handleRestoreHistory}
            onCurrent={handleCurrent}
          />
        ) : (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Badge
              dot={
                !!data.notes?.length ||
                (data?.history && data.history.length > 1)
              }
              color="volcano"
              offset={[-3, 3]}
            >
              <Button className={styles.btnIcon}>
                <EllipsisOutlined />
              </Button>
            </Badge>
          </Dropdown>
        )}
      </div>
      {!isOffset ? (
        <InfoAndNotesDrawer
          isCalculationLocked={isCalculationLocked}
          typeDrawer={typeDrawer}
          calculationEntry={data as Emission}
          visible={areNotesVisible}
          onClose={hideNotes}
          calculationEntryType={CalculationEntryType.EMISSIONS}
        />
      ) : (
        <InfoAndNotesDrawer
          isCalculationLocked={isCalculationLocked}
          typeDrawer={typeDrawer}
          calculationEntry={data as Offset}
          visible={areNotesVisible}
          onClose={hideNotes}
          calculationEntryType={CalculationEntryType.OFFSETS}
        />
      )}
      {isIndicatorModalVisible && (
        <IndicatorModal
          visible={isIndicatorModalVisible}
          defaultValue={(data as Emission).indicatorType}
          emissionName={(data as Emission).factor_data.name}
          onCancel={hideIndicatorModal}
          onConfirm={handleChangeIndicatorValue}
        />
      )}
      {isCategoryModalVisible && (
        <CategoryModal
          visible={isCategoryModalVisible}
          defaultValue={(data as Emission).category}
          emissionName={(data as Emission).factor_data.name}
          onCancel={hideCategoryModal}
          onConfirm={handleChangeCategoryValue}
        />
      )}
      {isRECModalVisible && (
        <RecModal
          visible={isRECModalVisible}
          defaultRecAmount={(data as Emission).recAmount || 0}
          defaultPercentageRecValue={(data as Emission).recPercent || 0}
          emissionName={(data as Emission).factor_data.name}
          emissionTotal={+(emissionTotal || 0)}
          onCancel={hideRECModal}
          onConfirm={handleChangeRECAmount}
        />
      )}
    </>
  );
};

export default TableActionsButtons;
