import React, { useEffect, useState } from "react";
import { Typography, Table, Space, Button, Input, Upload, Spin } from "antd";
import { CloseOutlined, InboxOutlined } from "@ant-design/icons";
import { ObjectId } from "bson";
import "./step2.scss";
import { v4 as uuidv4 } from "uuid";
const { Dragger } = Upload;
import SparkleIcon from "../../carbonPage/Calculator/SparkleIcon";
import TextArea from "antd/lib/input/TextArea";

interface Step2Props {
  form: any;
  initialValues: any;
  dataSheetsList: any[];
  onClickNext: () => Promise<void>;
  onClickPrevious: () => Promise<void>;
  sheetNames?: string[];
  rowData?: any;
  createNewAIProfile: (data: any) => void;
  updateAIProfile: (updatedData: any) => void;
  deleteAIProfile: (_id: any) => void;
}

interface AIProfile {
  _id: ObjectId;
  profile_name: string;
  prompt: string;
  system_prompt: string;
  last_modified: string;
  file_id: string;
  file_name: string;
}

const Step2 = ({
  form,
  onClickNext,
  onClickPrevious,
  initialValues,

  createNewAIProfile,
  updateAIProfile,
  deleteAIProfile,
}: Step2Props) => {
  const [ocrProfiles, setOcrProfiles] = useState<AIProfile[]>(
    initialValues?.ocr_profiles || []
  );
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentProfileBeingEdited, setCurrentProfileBeingEdited] = useState();
  useEffect(() => {
    setOcrProfiles(initialValues?.ocr_profiles || []);
  }, [initialValues]);

  const columns = [
    {
      title: "Last Modified Date",
      dataIndex: "last_modified",
      key: "last_modified",
      width: 200,
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Profile Name",
      dataIndex: "profile_name",
      key: "profile_name",
    },
    {
      title: "",
      key: "actions",
      width: 200,
      padding: 0,
      render: (_: any, record: any) => (
        <Space size="small">
          {/* <Button>File</Button> */}
          <Button
            onClick={() => {
              setIsDrawerVisible(true);
              setIsEditing(true);
              setCurrentProfileBeingEdited(record);
            }}
          >
            Edit
          </Button>
          <Button danger onClick={() => deleteAIProfile(record._id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const handleCreateNewProfileClick = () => {
    setIsDrawerVisible(true);
    setIsEditing(false);
    setCurrentProfileBeingEdited(undefined);
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  return (
    <>
      {isDrawerVisible && (
        <CreateNewProfileDrawer
          onClose={handleCloseDrawer}
          createNewAIProfile={createNewAIProfile}
          isEditing={isEditing}
          currentProfileBeingEdited={currentProfileBeingEdited}
          updateAIProfile={updateAIProfile}
        />
      )}
      <div className="ai-container">
        <div className="card">
          <h1 className="title">Manage AI Profiles</h1>

          <h2 className="subtitle">
            <strong>AI Profiles</strong> ({ocrProfiles.length} records)
          </h2>

          <div className="buttons-container">
            <div className="right">
              <Button onClick={handleCreateNewProfileClick}>
                <SparkleIcon /> Create New Profile
              </Button>
            </div>
          </div>

          <Table
            dataSource={ocrProfiles}
            columns={columns}
            pagination={false}
            style={{
              marginBottom: "20px",
              backgroundColor: "#3A3C5A",
              color: "#fff",
            }}
          />

          <div className="footer-buttons">
            <Button className="cancel" onClick={onClickPrevious}>
              Back
            </Button>
            <Button className="continue" onClick={onClickNext}>
              Continue to Step 3
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;

import { message } from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import moment from "moment";
import { uploadFileNew } from "../../../services/importData";

interface CreateNewProfileDrawerProps {
  onClose: () => void;
  createNewAIProfile: (data: any) => void;
  isEditing: boolean;
  updateAIProfile: (updatedData: any) => void;
  currentProfileBeingEdited: AIProfile | undefined;
}
interface UploadedFile extends UploadFile {
  file_id: string;
}

const CreateNewProfileDrawer: React.FC<CreateNewProfileDrawerProps> = ({
  onClose,
  createNewAIProfile,
  isEditing,
  updateAIProfile,
  currentProfileBeingEdited,
}) => {
  const [profileName, setProfileName] = useState<string>(
    isEditing && currentProfileBeingEdited
      ? currentProfileBeingEdited.profile_name
      : ""
  );
  const [prompt, setPrompt] = useState<string>(
    isEditing && currentProfileBeingEdited
      ? currentProfileBeingEdited.prompt
      : ""
  );
  const [systemPrompt, setSystemPrompt] = useState<string>(
    isEditing && currentProfileBeingEdited
      ? currentProfileBeingEdited.system_prompt
      : ""
  );
  const [files, setFiles] = useState<UploadedFile[]>(
    isEditing && currentProfileBeingEdited && currentProfileBeingEdited.file_id
      ? [
          {
            uid: currentProfileBeingEdited.file_id,
            name: currentProfileBeingEdited.file_name,
            file_id: currentProfileBeingEdited.file_id,
          },
        ]
      : []
  );
  const [uploadingFile, setUploadingFile] = useState(false);
  const isCreateButtonDisabled = !profileName || !prompt || !systemPrompt;

  const handleFileUpload = async (file: RcFile): Promise<boolean> => {
    try {
      const validExtensions = ["pdf", "jpeg", "jpg", "png"];
      const extension = file.name.split(".").pop()?.toLowerCase();
      if (!validExtensions.includes(extension!)) {
        message.error(`${file.name} is not a valid file type`);
        return false;
      }

      const fileName = `${moment().unix()}-${file?.name?.replace(
        /[/\\$#@?%*:|"<>]/g,
        ""
      )}`;
      const data_id = uuidv4();
      const payload = {
        file_category: "DATASHEET",
        version: 1,
        file_name: fileName,
        data_id: data_id,
      };

      setUploadingFile(true);
      const signedURL = await uploadFileNew(
        data_id,
        "",
        payload,
        "dataSheetFiles",
        "UPLOAD_FILE"
      );
      const response = JSON.parse(signedURL);

      await fetch(response.url, {
        method: "PUT",
        body: file,
      });

      const newFile: UploadedFile = {
        ...file,
        file_id: response.file_id,
        name: file.name,
      };

      setFiles([newFile]);
      setUploadingFile(false);
      return true;
    } catch (error) {
      setUploadingFile(false);
      message.error(`Failed to upload ${file.name}`);
      return false;
    }
  };

  const onRemoveFileFromListHandler = (file: UploadFile) => {
    setFiles((prevFiles) => prevFiles.filter((v) => v.uid !== file.uid));
  };

  const handleCreate = async (): Promise<void> => {
    if (isCreateButtonDisabled) {
      return;
    }

    const newProfile = {
      _id:
        isEditing && currentProfileBeingEdited
          ? currentProfileBeingEdited._id
          : new ObjectId().toString(),
      profile_name: profileName,
      prompt: prompt,
      system_prompt: systemPrompt,
      last_modified: new Date().toISOString(),
      file_id: files.length > 0 ? files[0].file_id : "",
      file_name: files.length > 0 ? files[0].name : "",
    };

    if (isEditing) updateAIProfile(newProfile);
    else createNewAIProfile(newProfile);
    onClose();
  };

  return (
    <div className="dark-container">
      <div className="profile-drawer-container">
        <div className="top">
          <div className="header">
            <CloseOutlined onClick={onClose} />{" "}
            <h2>{isEditing ? "Update" : "Create New"} AI Profile</h2>
          </div>
          <div className="content">
            <div className="input-field" style={{ flex: "1" }}>
              <label htmlFor="profileName">
                Profile Name <span style={{ color: "red" }}>*</span>
              </label>
              <Input
                id="profileName"
                name="profileName"
                placeholder="Enter profile name"
                style={{ width: "100%" }}
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </div>
            <div className="input-field" style={{ flex: "2" }}>
              <label htmlFor="prompt">
                Prompt <span style={{ color: "red" }}>*</span>
              </label>
              <TextArea
                id="prompt"
                name="prompt"
                placeholder="Enter prompt here"
                rows={4}
                style={{ width: "100%" }}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
            </div>
            <div className="input-field" style={{ flex: "2" }}>
              <label htmlFor="systemPrompt">
                System Prompt <span style={{ color: "red" }}>*</span>
              </label>
              <TextArea
                id="systemPrompt"
                name="systemPrompt"
                placeholder="Enter system prompt here"
                rows={4}
                style={{ width: "100%" }}
                value={systemPrompt}
                onChange={(e) => setSystemPrompt(e.target.value)}
              />
            </div>
            <div className="input-field">
              <label htmlFor="uploadData">Upload Data (Optional)</label>
              <Dragger
                name="file"
                multiple={false}
                accept=".pdf, .jpeg, .jpg, .png"
                beforeUpload={handleFileUpload}
                fileList={files}
                onRemove={onRemoveFileFromListHandler}
                style={{
                  padding: "20px",
                  backgroundColor: "#625D75",
                  borderRadius: "4px",
                }}
              >
                <p className="ant-upload-drag-icon">
                  {uploadingFile ? <Spin /> : <InboxOutlined />}
                </p>

                {files.length > 0 ? (
                  <p>
                    Click or drag file to this area to <strong>replace</strong>{" "}
                    the current uploaded file
                  </p>
                ) : (
                  <p>Click or drag file to this area to upload</p>
                )}
                <p>Supports a single file in PDF, JPEG, JPG, or PNG format.</p>
              </Dragger>
            </div>
          </div>
        </div>
        <div className="footer-buttons">
          <Button
            className="continue"
            onClick={handleCreate}
            disabled={isCreateButtonDisabled}
          >
            {isEditing ? "Update" : "Create"}
          </Button>{" "}
          <Button className="cancel" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
