import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, Checkbox, Col, message, Row, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getUserGroups, getUsersList } from "../../../services/users";
import {
  addTaskDeployment,
  sendEmailReminderToUser,
} from "../../../services/taskDeployment";
import { fetchProfileImage } from "../../../shared/commonFunctions";
import {
  FormWrap,
  FormWrapper,
  PageWrap,
  StyledButton,
  StyledProgress,
  StyledSteps,
} from "../../../shared/commonStyles";
import { AddUsersImport } from "./AddUsersImport";
import { AddUsersManually } from "./AddUsersManually";
import styles from "./AddUsers.module.scss";
import { listPermissions } from "../../../services/permissions";
import { encodeUrlName } from "../../../shared/commonFunctions";

interface SurveyInfo {
  surveyId: string;
  entityIds?: string[];
  defaultMetadataSheetId?: string;
  surveyName: string;
  type?: string;
  taskDeployments?: SurveyDeployment[];
}

interface UserGroupInfo extends UserGroup {
  assigned?: boolean;
}

interface UserInfo extends User {
  profileImage?: string;
  assigned?: boolean;
}

export const AddUsers = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [usersList, setUsersList] = useState<UserInfo[]>([]);
  const [groupsList, setGroupsList] = useState<UserGroupInfo[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [isEmailNeeded, setIsEmailNeeded] = useState(true);

  const getUsers = async () => {
    const users: User[] = await getUsersList();
    const usersInfo: UserInfo[] = [];
    await Promise.all(
      users.map(async (user) => {
        const email = user.Attributes.find(
          (item) => item.Name === "email"
        )?.Value;
        const isAssigned = !!(state as SurveyInfo)?.taskDeployments?.find(
          (item) => !item.assignedGroup && item.email === email
        );
        let profileImage = "";
        try {
          profileImage = await fetchProfileImage(user.value);
        } catch (error) {
          profileImage = "";
        }
        const newUser = { ...user, profileImage, assigned: isAssigned };
        usersInfo.push(newUser);
      })
    );
    setUsersList(usersInfo);
  };
  const getGroups = async () => {
    const groups: UserGroup[] = await getUserGroups();
    const groupsInfo: UserGroupInfo[] = [];
    groups.map((group) => {
      const assignedGroup = group._id.$oid;
      const isAssigned = !!(state as SurveyInfo)?.taskDeployments?.find(
        (item) => item.assignedGroup === assignedGroup
      );
      const newGroup = { ...group, assigned: isAssigned, isGroup: true };
      groupsInfo.push(newGroup);
    });
    setGroupsList(groupsInfo);
  };

  useEffect(() => {
    getUsers();
    getGroups();
  }, []);

  const handleGoBack = () => {
    navigate(
      `/management/collect-data/${
        (state as SurveyInfo).surveyId
      }/${encodeUrlName((state as SurveyInfo).surveyName)}/deploy-survey`,
      {
        state: {
          surveyName: (state as SurveyInfo).surveyName,
          surveyId: (state as SurveyInfo).surveyId,
          entityIds: (state as SurveyInfo).entityIds,
          defaultMetadataSheetId: (state as SurveyInfo).defaultMetadataSheetId,
        },
      }
    );
  };

  const checkUserPermissions = async (email: string, username?: string) => {
    const payload = {
      permissionType: "REFERENCE_DATA",
      itemId: (state as SurveyInfo).defaultMetadataSheetId,
      $or: [{ username: { $eq: username } }, { email: { $eq: email } }],
      searchByGroups: true,
    };
    const permissionsData = await listPermissions(payload);

    return permissionsData;
  };

  const checkGroupPermissions = async (group: string) => {
    const payload = {
      permissionType: "REFERENCE_DATA",
      itemId: (state as SurveyInfo).defaultMetadataSheetId,
      groupId: group,
    };
    const permissionsData = await listPermissions(payload);

    return permissionsData;
  };

  let isCreatedDeployment = false;

  const handleCreateDeployment = async (
    payload: any,
    email = "group-no-email@hydrus.ai"
  ) => {
    const response = await addTaskDeployment(payload);
    if (response && isEmailNeeded) {
      const emailResponse = await sendEmailReminderToUser(
        "SURVEY",
        response.data.createTaskDeployment.id
      );
      if (!emailResponse) {
        message.warning("Did not get to contact " + payload?.email);
      }

      isCreatedDeployment = true;
    }
  };

  const handleAssignUsers = async () => {
    const selectedUsersEmails =
      usersList
        .filter((user) => user.assigned)
        ?.map(
          (user) =>
            user.Attributes.find((item) => item.Name === "email")?.Value || ""
        ) || [];
    const selectedEmails = [...selectedUsersEmails, ...emails];
    const selectedUsersObjects = usersList.filter((user) => user.assigned);

    const existingUserEmails =
      (state as SurveyInfo).taskDeployments
        ?.filter((item) => !item.assignedGroup)
        .map((item) => item.email) || [];
    for (const email of selectedEmails) {
      const username = usersList?.find((user) => {
        return (
          user.Attributes.find((item) => item.Name === "email")?.Value === email
        );
      })?.Username;

      const permissions =
        !(state as SurveyInfo).entityIds?.length &&
        (await checkUserPermissions(email, username));
      const assignedItems: string[] = (state as SurveyInfo).entityIds?.length
        ? (state as SurveyInfo).entityIds
        : permissions?.map((item: any) => item?.assignedItems)?.flat();
      const userAddedEntities = (state as SurveyInfo)?.taskDeployments
        ?.filter((item) => !item.assignedGroup && item.email === email)
        ?.map((item) => item?.entityId);
      let filteredAssignedItems = [
        ...new Set(
          assignedItems?.filter(
            (item: string) => !userAddedEntities?.find((v) => item === v)
          )
        ),
      ];
      let requestPayloads: any = [];

      selectedUsersObjects.forEach((user: any) => {
        requestPayloads.push({
          permissionType: "REFERENCE_DATA",
          username: user.Username,
        });
      });

      const currentUserObject = selectedUsersObjects.find((user) =>
        user.Attributes.some(
          (attribute) => attribute.Name === "email" && attribute.Value === email
        )
      );

      console.log(currentUserObject);
      if (currentUserObject) {
        try {
          const userAssignedEntities = await listPermissions({
            permissionType: "REFERENCE_DATA",
            username: currentUserObject.Username,
          });

          console.log("user assigned entities", userAssignedEntities);

          const allAssignedItems = userAssignedEntities.flatMap(
            (entity: any) => entity.assignedItems
          );

          console.log("combined assigned items", allAssignedItems);

          filteredAssignedItems = filteredAssignedItems.filter((item) =>
            allAssignedItems.includes(item)
          );

          console.log("filteredAssignedItems", filteredAssignedItems);
        } catch (error) {
          console.error("Error fetching permissions:", error);
        }
      }

      const isPreviouslyAssigned = existingUserEmails.includes(email);
      if (filteredAssignedItems?.length) {
        await Promise.all(
          filteredAssignedItems?.map(async (entityId: string) => {
            const payload = {
              email: email,
              status: "Not contacted",
              completion_date: null,
              surveyManagementID: (state as SurveyInfo).surveyId,
              entityId: entityId,
            };
            await handleCreateDeployment(payload, email);
          })
        );
      } else if (!isPreviouslyAssigned) {
        const payload = {
          email: email,
          status: "Not contacted",
          completion_date: null,
          surveyManagementID: (state as SurveyInfo).surveyId,
        };
        await handleCreateDeployment(payload, email);
      }
    }
  };
  const handleAssignGroups = async () => {
    const selectedGroups = groupsList
      ?.filter((group) => group.assigned)
      .map((group) => group._id.$oid);
    const existingGroups = (state as SurveyInfo).taskDeployments?.map(
      (item) => item.assignedGroup
    );
    for (const group of selectedGroups) {
      const permissions =
        !(state as SurveyInfo).entityIds?.length &&
        (await checkGroupPermissions(group));
      const assignedItems: string[] = (state as SurveyInfo).entityIds?.length
        ? (state as SurveyInfo).entityIds
        : permissions?.map((item: any) => item?.assignedItems)?.flat();
      const userAddedEntities = (state as SurveyInfo)?.taskDeployments
        ?.filter((item) => item.assignedGroup === group)
        ?.map((item) => item?.entityId);
      const filteredAssignedItems = [
        ...new Set(
          assignedItems?.filter(
            (item: string) => !userAddedEntities?.find((v) => item === v)
          )
        ),
      ];
      const isPreviouslyAssigned = existingGroups?.includes(group);
      if (filteredAssignedItems?.length) {
        await Promise.all(
          filteredAssignedItems?.map(async (entityId: string) => {
            const payload = {
              assignedGroup: group,
              email: "group-no-email@hydrus.ai",
              status: "Not contacted",
              completion_date: null,
              surveyManagementID: (state as SurveyInfo).surveyId,
              entityId: entityId,
            };
            await handleCreateDeployment(payload);
          })
        );
      } else if (!isPreviouslyAssigned) {
        const payload = {
          assignedGroup: group,
          email: "group-no-email@hydrus.ai",
          status: "Not contacted",
          completion_date: null,
          surveyManagementID: (state as SurveyInfo).surveyId,
        };
        await handleCreateDeployment(payload);
      }
    }
  };

  const handleAssign = async () => {
    setSubmitting(true);
    try {
      await handleAssignUsers();
      await handleAssignGroups();
      isCreatedDeployment &&
        message.success(
          "Task deployment created and notification send to users successfully."
        );
      handleGoBack();
    } catch (error) {
      console.error("Error assigning users:", error);
      message.error("An error occurred while assigning users.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Row className={styles.header}>
        <Col lg={1} md={4} sm={7} xs={10}>
          <StyledButton
            type="custom"
            onClick={handleGoBack}
            hovercolor="#A68DFB"
            hoverbgcolor="transparent"
            bgcolor="transparent"
            bordercolor="transparent"
            color="#fff"
          >
            <ArrowLeftOutlined /> <span>Exit</span>
          </StyledButton>
        </Col>
        <Col
          className="user-flow-steps-wrap color-white"
          lg={22}
          md={19}
          sm={16}
          xs={13}
        >
          <StyledSteps current={1}>
            <>
              <StyledSteps.Step
                className="stepper-wrapper "
                key={1}
                title={
                  <span>
                    {(state as SurveyInfo)?.type === "importUsers"
                      ? "Import users"
                      : "Add users manually"}
                  </span>
                }
              />
            </>
          </StyledSteps>
        </Col>
      </Row>
      <StyledProgress
        strokeLinecap="square"
        strokeWidth={10}
        percent={50}
        showInfo={false}
        className={styles.progress}
      />
      <PageWrap>
        <Spin spinning={submitting}>
          <FormWrap width={"600px"}>
            {(state as SurveyInfo)?.type === "importUsers" ? (
              <AddUsersImport setEmails={setEmails} />
            ) : (
              <AddUsersManually
                usersList={usersList}
                groupsList={groupsList}
                setUsersList={setUsersList}
                setGroupsList={setGroupsList}
                emails={emails}
                setEmails={setEmails}
                taskDeployments={(state as SurveyInfo)?.taskDeployments}
              />
            )}
            <FormWrapper>
              <Row>
                <Checkbox
                  checked={isEmailNeeded}
                  onChange={(e) => setIsEmailNeeded(e.target.checked)}
                >
                  Send invitation emails
                </Checkbox>
              </Row>
            </FormWrapper>
            <Row className={styles.footer}>
              <Col>
                <Button type="text" onClick={handleGoBack}>
                  Cancel
                </Button>
                <StyledButton
                  type="custom"
                  padding={"5px 12px"}
                  bgcolor={"#7F5FEE"}
                  bordercolor={"#7F5FEE"}
                  onClick={handleAssign}
                  loading={submitting}
                >
                  Assign
                </StyledButton>
              </Col>
            </Row>
          </FormWrap>
        </Spin>
      </PageWrap>
    </>
  );
};
