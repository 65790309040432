import * as queries from "../graphql/queries";

import { API, Auth, graphqlOperation } from "aws-amplify";

import _ from "lodash";
import { datalakeVarianceDetection } from "../graphql/queries";

import { message } from "antd";
import { additionalQueryOperators, queryOperators } from "./mongoOperators";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

// find whether a datasheet is a referencesheet or not
export const is_reference_sheet = (dataSheet) => {
  return (
    dataSheet?.is_reference_data ||
    metadataSheetTypesList.find((v) => v === dataSheet?.sheet_type)
  );
};

export const getDatasheetFilters = async (payload) => {
  const data = await Auth.currentSession();
  const group = data.accessToken.payload["cognito:groups"].filter((element) =>
    element.includes("org:")
  )[0];
  const sheetsPayload = { ...payload, filter: { ...payload?.filter, group } };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_datasheet_filters",
      ...(sheetsPayload && {
        request_payload: JSON.stringify(sheetsPayload),
      }),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const getDataSheetsWithPagination = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "list_sheets_paginated",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const getSheetRecordsFilters = async (
  sheetId,
  filtration_fields,
  record_types
) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_records_filters",
      request_payload: JSON.stringify({
        sheet_id: sheetId,
        filtration_fields,
        record_types,
      }),
    },
  });
  return JSON.parse(response.data.datalake);
};

export const metadataSheetTypesList = [
  "Metadata",
  "Real Estate List",
  "Supplier List",
  "Portfolio Company List",
  "Custom Metadata",
];

export const createDataSheet = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group, username };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "create_sheet",
      request_payload: JSON.stringify(newPayload),
    },
  });
  return response;
};

export const updateDataSheet = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group, username };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_sheet_settings",
      request_payload: JSON.stringify(newPayload),
    },
  });
  return response;
};

export const uploadOCRFile = async (presigned_url, files) => {
  const f = files[0];

  await fetch(presigned_url, {
    method: "PUT",
    body: f,
  });
};

export const getPresignedUrl = async (file_name, import_id) => {
  const data_id = uuidv4();

  const response = await API.graphql({
    query: queries["dataSheetFiles"],
    variables: {
      request_type: "UPLOAD_FILE",
      request_payload: JSON.stringify({
        file_name: file_name,
        file_category: "DATASHEET",
        version: 1,
        data_id: data_id,
      }),
      data_id: data_id,
      import_id: import_id,
    },
  });
  return response;
};

export const getDataSheets = async (payload) => {
  const data = await Auth.currentSession();
  const group = data.accessToken.payload["cognito:groups"].filter((element) =>
    element.includes("org:")
  )[0];
  const sheetsPayload = { ...payload, filter: { ...payload?.filter, group } };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "list_sheets",
      request_payload: JSON.stringify(sheetsPayload),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const getSheetById = async (
  dataSheetid,
  includeConvertableUnits = true
) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_sheet_by_id",
      request_payload: JSON.stringify({
        sheet_id: dataSheetid,
        include_convertable_units: includeConvertableUnits,
      }),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

const defaultDataSheetRecordsFilter = {
  $and: [
    {
      $or: [
        { data_approval_status: true },
        { data_approval_status: { $exists: false } },
      ],
    },
    {
      review_status: {
        $nin: ["DENIED", "PENDING", "IN_PROGRESS"],
      },
    },
  ],
};

export const makeQuery = (params, dataSheet, recordTypes, recordFilter) => {
  const { pagination, filters, sorter, operatorType, conditionalFilters } =
    params;
  const matchKeyPrefix = dataSheet?.sheet_name;

  // Build sorting object from sorter parameter
  const sortingResult = {};
  if (sorter && sorter.order) {
    sortingResult[
      sorter.field === "key" ? "_id" : `${matchKeyPrefix}.${sorter.field}`
    ] = sorter.order === "ascend" ? 1 : -1;
  }

  // Convert filters for MongoDB query
  const newFilters = filters?.map(
    (item) =>
      (item.op === "isEmpty" && { ...item, column_value: true }) ||
      (item.op === "isNotEmpty" && {
        ...item,
        op: "isEmpty",
        column_value: false,
      }) ||
      item
  );

  // Determine which sorting logic to use
  const combinedSort = Object.keys(recordFilter?.sort || {}).length
    ? recordFilter.sort // Use sort from recordFilter if it exists
    : sortingResult; // Otherwise, fallback to sortingResult

  // Construct the query payload
  const payload = {
    skip: pagination ? (pagination.current - 1) * pagination.pageSize : 0,
    limit: pagination ? pagination.pageSize : 10,
    ...(recordTypes && { record_types: recordTypes }),
    filter: recordFilter?.filter || defaultDataSheetRecordsFilter,
    ...(newFilters?.length && { filters_list: newFilters }),
    ...(conditionalFilters?.length && {
      conditional_filters: convertConditionalFilters(conditionalFilters),
    }),
    ...(operatorType && { operator_type: operatorType }),
    ...(Object.keys(combinedSort).length && { sort: combinedSort }), // Use combined sorting logic
    sheet_id: dataSheet?._id?.$oid,
  };

  return payload;
};

const convertConditionalFilters = (filters) => {
  const newFilters = filters.map((filter) =>
    filter.type === "condition"
      ? {
          ...filter,
          value: {
            ...filter.value,
            op: filter.value.op === "isNotEmpty" ? "isEmpty" : filter.value.op,
            column_value:
              filter.value.op === "isEmpty"
                ? true
                : filter.value.op === "isNotEmpty"
                ? false
                : filter.value.column_value,
          },
        }
      : filter
  );
  return newFilters;
};

export const fetchRecordData = async (requestPayload) => {
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "fetch_record_data_1",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const aggregateRecords = async (
  datasheetId,
  dataSheetName,
  conditionalFilters,
  aggregationFields
) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "aggregate_records",
      request_payload: JSON.stringify({
        sheet_id: datasheetId,
        sheet_name: dataSheetName,
        aggregate_fields: aggregationFields,
        conditional_filters: convertConditionalFilters(conditionalFilters),
        filters: defaultDataSheetRecordsFilter,
      }),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const countMissingFields = async (
  datasheetId,
  dataSheetName,
  conditionalFilters,
  aggregationFields
) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "count_missing_fields",
      request_payload: JSON.stringify({
        sheet_id: datasheetId,
        sheet_name: dataSheetName,
        fields: aggregationFields,
        filters: defaultDataSheetRecordsFilter,
        conditional_filters: convertConditionalFilters(conditionalFilters),
      }),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const insertData = async (requestPayload) => {
  const data = await Auth.currentSession();
  const group = data.accessToken.payload["cognito:groups"].filter((element) =>
    element.includes("org:")
  )[0];
  const username = data.accessToken.payload.username;
  requestPayload.username = username;
  requestPayload.group_id = group;
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "add_records",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const updateData = async (requestPayload) => {
  const data = await Auth.currentSession();
  const username = data.accessToken.payload.username;
  if (requestPayload.isAdminReview) {
    requestPayload.approver_username = username;
    delete requestPayload.isAdminReview;
  }
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_record",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response["data"]["datalake"]);
};

export const sendUserDenyReminder = async (id) => {
  const response = await API.graphql({
    query: queries["sendTaskReminder"],
    variables: {
      request_type: "RECORD_DENIAL",
      record_id: id,
    },
  });
  return response["data"]["sendTaskReminder"];
};

export const sendUserApprovalNotification = async (id) => {
  const response = await API.graphql({
    query: queries["sendTaskReminder"],
    variables: {
      request_type: "RECORD_APPROVAL",
      record_id: id,
    },
  });
  return response["data"]["sendTaskReminder"];
};

export const massUpdateRecords = async (requestPayload) => {
  const newFilters = requestPayload.filters || defaultDataSheetRecordsFilter;
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "mass_update_records",
      request_payload: JSON.stringify({
        ...requestPayload,
        filters: newFilters,
      }),
    },
  });
  return JSON.parse(response.data.datalake);
};
export const partialUpdateRecord = async (requestPayload) => {
  const data = await Auth.currentSession();
  const username = data.accessToken.payload.username;
  if (requestPayload.isAdminReview) {
    requestPayload.approver_username = username;
    delete requestPayload.isAdminReview;
  }
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "partial_update_record",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response["data"]["datalake"]);
};
export const addLastAnalyticsRecord = async (requestPayload) => {
  const data = await Auth.currentSession();
  const username = data["accessToken"]["payload"]["username"];

  const newRequestPayload = { ...requestPayload, username };
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_last_analytics_record",
      request_payload: JSON.stringify(newRequestPayload),
    },
  });

  return response;
};

export const getMessageType = (messageType, messageText) => {
  switch (messageType) {
    case "success":
      message.success(messageText);
      break;
    case "warning":
      message.warning(messageText);
      break;
    case "info":
      message.info(messageText);
      break;
    default:
      message.info(messageText);
      break;
  }
};

export const getMessageByType = (item, key) => {
  const messageType = item.messageType;

  const keys = item?.primaryKeysValues
    ? `: ${Object.entries(item.primaryKeysValues).map((pair) => {
        return ` ${pair[0]}: ${pair[1]}`;
      })}`
    : "";
  const messageText = `${key}: ${item.message}` + `${keys}`;
  getMessageType(messageType, messageText);
};
export const massApproveWithDeduplication = async (requestPayload) => {
  const newFilters = requestPayload.filters || defaultDataSheetRecordsFilter;
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "mass_approve_with_deduplication",
      request_payload: JSON.stringify({
        ...requestPayload,
        filters: newFilters,
      }),
    },
  });
  const result = JSON.parse(response.data.datalake);
  Object.entries(result).map(([key, value]) => {
    return value?.messages?.map((item) => getMessageByType(item, key));
  });
  return result;
};
export const modifyMetadataStatus = async (requestPayload) => {
  const resp = await API.graphql({
    query: queries["datalakeImportData"],
    variables: {
      request_type: "MODIFY_METADATA_STATUS",
      import_id: requestPayload.importId,
      import_status: requestPayload.importStatus,
      sheet_id: requestPayload?.sheetId,
    },
  });
  return resp;
};
export const getModifiedForMetaData = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_modified_for_metadata",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  const metaData = JSON.parse(response["data"]["datalake"]);
  const metaDataRecord = metaData[0];
  return metaDataRecord;
};

export const getHistoryForDataSheet = async (recordId) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "list_records_revisions_1",
      request_payload: JSON.stringify({ record_id: recordId }),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const doConversion = async (
  value,
  fromUnit,
  toUnit,
  customConversions
) => {
  if (fromUnit === toUnit) return value;
  const response = await API.graphql({
    query: queries.datalake,
    variables: {
      request_type: "convert_units",
      request_payload: JSON.stringify({
        conversion_id: `${fromUnit}_${toUnit}`,
        value,
        ...(customConversions && { customConversions }),
      }),
    },
  });
  const result = JSON.parse(response["data"]["datalake"]);
  const isError = result?.statusCode >= 400;
  isError && message.error(result?.body);
  return isError ? value : result["converted_value"];
};

export const getUnits = async () => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "list_units_from_groups",
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const makeQueryMetadata = (params) => {
  const {
    sheetId,
    pagination,
    filters,
    filter,
    sorter,
    operatorType,
    recordTypes,
  } = params;
  const sortingResult = {};
  const newFilters = filters?.length
    ? filters?.map((item) =>
        item.op === "isEmpty"
          ? { ...item, op: "isEmpty", column_value: true }
          : item.op === "isNotEmpty"
          ? { ...item, op: "isEmpty", column_value: false }
          : item
      )
    : [];
  if (sorter && sorter.type) {
    sortingResult[sorter.type === "key" ? "id" : `${sorter.type}`] =
      sorter.sortAlphabetically ? 1 : -1;
  }
  const payload = {
    skip: pagination ? (pagination.current - 1) * pagination.pageSize : 0,
    limit: pagination ? pagination.pageSize : 10,
    filters_list: newFilters,
    filter,
    operator_type: operatorType || "$and",
    record_types: recordTypes || {
      include: [],
      exclude: ["archived", "imported_pending"],
    },
    sort: sortingResult,
    sheet_id: sheetId,
  };
  return payload;
};

export const listMetaDataRecords = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "list_metadata_records",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake.replace(/NaN/g, null));
};

export const getMetaDataRecords = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_metadata_records",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return JSON.parse(response.data.datalake.replace(/NaN/g, null));
};

export const insertMetaDataRecord = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_metadata_records_modified",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  // insertMeta will return NaN and need replace that with null as it will return an error when trying to parse
  const cleaned_response = response["data"]["datalake"].replace(
    /"NaN"|NaN/g,
    "null"
  );
  return JSON.parse(cleaned_response);
};

export const updateMetaDataRecord = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "update_metadata_records_modified",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  // updateMeta will return NaN and need replace that with null as it will return an error when trying to parse
  const cleaned_response = response["data"]["datalake"].replace(
    /"NaN"|NaN/g,
    "null"
  );
  return JSON.parse(cleaned_response);
};

export const listDataSheetFiles = async (dataId) => {
  const response = await API.graphql({
    query: queries["dataSheetFiles"],
    variables: { request_type: "LIST", data_id: dataId },
  });
  return response["data"]["dataSheetFiles"];
};
export const listDataSheetFilesNew = async (filesList) => {
  const requestPayload = {
    filter: {
      _id: { $oid: filesList },
    },
  };
  const response = await API.graphql({
    query: queries["dataSheetFiles"],
    variables: {
      request_type: "LIST_FILES",
      data_id: "",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return response["data"]["dataSheetFiles"];
};

export const uploadDataSheetFile = async (dataId, fileName) => {
  const response = await API.graphql({
    query: queries["dataSheetFiles"],
    variables: {
      request_type: "UPLOAD",
      data_id: dataId,
      version: 1,
      file_name: fileName,
    },
  });
  return response["data"]["dataSheetFiles"];
};

export const uploadDataSheetFileNew = async (fileName) => {
  const requestPayload = {
    file_category: "GENERAL",
    version: 1,
    file_name: fileName,
  };

  const response = await API.graphql({
    query: queries["dataSheetFiles"],
    variables: {
      request_type: "UPLOAD_FILE",
      data_id: "",
      request_payload: JSON.stringify(requestPayload),
    },
  });
  return response["data"]["dataSheetFiles"];
};

export const listDataSheetAuditFiles = async (dataId) => {
  const response = await API.graphql({
    query: queries["dataSheetAuditFiles"],
    variables: { request_type: "AUDIT_LIST", data_id: dataId },
  });
  return response["data"]["dataSheetAuditFiles"];
};

export const uploadDataSheetAuditFile = async (dataId, fileName) => {
  const response = await API.graphql({
    query: queries["dataSheetAuditFiles"],
    variables: {
      request_type: "AUDIT_UPLOAD",
      data_id: dataId,
      version: 1,
      file_name: fileName,
    },
  });
  return response["data"]["dataSheetAuditFiles"];
};

export const addRecordNote = async (recordId, note, isFieldNote) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "add_record_note",
      request_payload: JSON.stringify({
        record_id: recordId,
        note,
        isFieldNote,
      }),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};
export const dataSheetFileExport = async (
  request_type,
  sheet_id,
  sheet_name,
  filterData
) => {
  const response = await API.graphql({
    query: queries["dataSheetFileExport_job"],
    variables: {
      request_type,
      sheet_id,
      sheet_name,
      filter: JSON.stringify({
        filters_object: filterData.filter,
        filters_list: filterData.filters_list,
        operator_type: filterData.operator_type,
        conditional_filters: filterData.conditional_filters,
      }),
    },
  });
  const responseExport = response?.["data"]["dataSheetFileExport_job"];
  const responseMatch = responseExport.match(/job_id=([^;\n]+)/);
  const responseName = responseMatch[1].replace(/}/g, "");
  return responseName;
};

export const getDataLakeVarianceDetection = async (sheet_id) => {
  try {
    const totalCost = await API.graphql(
      graphqlOperation(datalakeVarianceDetection, {
        request_type: "get_datasheet_variance",
        request_payload: JSON.stringify({
          sheet_id,
        }),
      })
    );
    return JSON.parse((await totalCost).data?.datalakeVarianceDetection || "");
  } catch (error) {
    console.log("error", error);
  }
};

export const getLastAggregateAnalytics = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_last_aggregate_analytics",
      request_payload: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalake);
};

export const calculateAirDistance = async (requestPayload) => {
  const response = await API.graphql({
    query: queries["datalakeImportData"],
    variables: {
      request_type: "MANUAL_AIR",
      trip_details: JSON.stringify(requestPayload),
    },
  });

  return JSON.parse(response.data.datalakeImportData);
};

export const getRecordsQueryByRole = async (sheetId) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_records_query_by_role",
      request_payload: JSON.stringify({ sheet_id: sheetId }),
    },
  });

  return JSON.parse(response["data"]["datalake"]);
};
export const getAssignedIdentifiers = async (metadata_sheet_id) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_assigned_identifiers",
      request_payload: JSON.stringify({ metadata_sheet_id: metadata_sheet_id }),
    },
  });
  return JSON.parse(response["data"]["datalake"]);
};

export const getAssignedIdentifiersForDefaultMetadata = async (payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_assigned_identifiers_for_default_metadata",
      request_payload: JSON.stringify(payload),
    },
  });

  return JSON.parse(response["data"]["datalake"]);
};

export const getRecordsQueryByIdentifiers = async (request_payload) => {
  const response = await API.graphql({
    query: queries["datalake"],
    variables: {
      request_type: "get_records_query_by_identifiers",
      request_payload: JSON.stringify(request_payload),
    },
  });

  return JSON.parse(response["data"]["datalake"]);
};

export const convertFiltersList = (
  filters,
  dataSheet,
  operatorType = "$and"
) => {
  const newFilters = [];
  filters.forEach((filter, index) => {
    newFilters.push({ value: filter, type: "condition", label: "" });
    index !== filters?.length - 1 &&
      newFilters.push(
        (operatorType === "$and" && {
          value: "$and",
          type: "operator",
          label: "AND",
        }) || { value: "$or", type: "operator", label: "OR" }
      );
  });
  const updatedFilters = newFilters.map((filter) => {
    const column = dataSheet?.sheet_schema?.find(
      (v) => v.entity_name === filter?.value?.column
    );
    const newValue =
      filter.value.op === "isEmpty" || filter.value.op === "isNotEmpty"
        ? null
        : filter.value.column_value;
    const conditionalFilter = `${column?.display_name} ${
      [...queryOperators, ...additionalQueryOperators].find(
        (item) => item.value === filter.value.op
      )?.label
    } ${
      filter.value.op !== "isEmpty" && filter.value.op !== "isNotEmpty"
        ? column?.data_type === "date" || column?.input_type === "date"
          ? moment(filter.value.column_value)?.format("YYYY-MM-DD")
          : filter.value.column_value
        : ""
    }`;
    const label =
      filter.type === "condition" && !filter.label
        ? conditionalFilter
        : filter.label;
    return {
      ...filter,
      label,
      value:
        filter.type === "condition"
          ? {
              ...filter.value,
              column_value: newValue,
              op: filter.value.op,
            }
          : filter.value,
    };
  });
  return updatedFilters;
};
