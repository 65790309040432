import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBoolean } from "ahooks";
import {
  CaretRightFilled,
  QuestionCircleFilled,
  EditFilled,
  SwapOutlined,
  DeleteFilled,
  RedoOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";
import {
  Button,
  Row,
  Col,
  message,
  Collapse,
  Dropdown,
  Spin,
  Popover,
  Tooltip,
  Select,
} from "antd";
import EditableCalculationTable from "./Components/EditableCalculationTable";
import { ConfirmModal } from "./Components/ConfirmModal";
import { calculatorStore, carbonStore } from "../../Carbon";
import {
  fractionalNumber,
  getEmissionsUnit,
  getScopeNumber,
} from "../../Carbon/CalculationUtils";
import { getAssignedIdentifiersForDefaultMetadata } from "../../../services/dataSheet";
import { SortDropdown } from "../../../components/SortDropdown";
import { AddEmissionsModal } from "./Components/AddEmissionsModal";
import { EditCalculationModal } from "./Components/EditCalculationModal";
import { calculationStore } from "../../Carbon/CalculationsStore";
import EditableCalculationOffsetsTable from "./Components/EditableCalculationOffsetsTable/EditableCalculationOffsetsTable";
import DeletedItemsTable from "../DeletedItems/components/DeletedItemsTable/DeletedItemsTable";
import { AddOffset } from "./Components/AddOffset";
import { v4 as uuidv4 } from "uuid";
import { SortDropdownCalculator } from "./Components/SortDropdownCalculator";
import styles from "./Calculator.module.scss";
import CalculationStyles from "../Calculations/Calculations.module.scss";
import { PageWrap, StyledButton } from "../../../shared/commonStyles";
import { getCompanyInfo } from "../../../services/companyInfo";
import { doConversion } from "../../../services/dataSheet";
import moment from "moment";
import { calculationGroupsStore } from "../../Carbon/CalculationGroupsStore";
import { encodeUrlName } from "../../../shared/commonFunctions";
import {
  fetchDataWithRetry,
  getJobExecutionStatus,
} from "../../../services/importData";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import { aiIntegration } from "../../../services/dataIntegration";
import { ReadinessScoreComponent } from "./ReadinessScoreComponent";

const numeral = require("numeral");

interface CalculatorProps {
  isDeleted: boolean;
}
interface CalculationState {
  calcId: string;
  groupId?: string;
}

export interface ReadinessScoreItem {
  key: string;
  name: string;
  description: string;
  value: number | boolean | string | Record<string, number> | string[];
  reasoning: string;
}

export interface AiValidatorData {
  overall_readiness_score: number;
  items: ReadinessScoreItem[];
}

export const validateGhgCalculation = async (
  data: any,
  calculatingEntireCalculation: boolean
) => {
  let payload: any = { ...data };

  payload["name"] = calculatingEntireCalculation
    ? "VALIDATE_ENTIRE_GHG_CALCULATION"
    : "VALIDATE_GHG_CALCULATION";

  try {
    const response = await aiIntegration({
      ...payload,
    });

    let str = response.data.aiOperation_job;

    let match = str.match(/job_id=([a-zA-Z0-9\-]+)/);
    let job_id = match ? match[1] : null;

    if (job_id) {
      const maxRetries = 100;
      let retryCount = 0;
      while (retryCount < maxRetries) {
        try {
          const payload = {
            id: job_id,
          };
          const response = await getJobExecutionStatus(payload);

          if (!response.status) {
            throw new Error("Failed to fetch data");
          }

          if (response.status !== "IN_PROGRESS") {
            // setAiValidatorData(JSON.parse(response.resultSummary));
            return JSON.parse(response.resultSummary);
          }
          retryCount++;
          if (retryCount < maxRetries) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        } catch (error) {
          console.error("Error fetching data:");
          retryCount++;
          if (retryCount < maxRetries) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
          } else {
            throw new Error("Max retries reached");
          }
        }
      }
    }
  } catch (error) {
    console.error("Error during API call:", error);
  }
};

const Calculator = observer(function DataSheetDocuments({
  isDeleted,
}: CalculatorProps) {
  const [emissionData, setEmissionData] = useState<Emission[]>([]);
  const [offsets, setOffsets] = useState<Offset[]>([]);
  const [calculation, setCalculation] = useState<ICalculation>();
  const [conversionFactor, setConversionFactor] = useState(1);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assignedIdentifiers, setAssignedIdentifiers] = useState([]);
  const [selectedIdentifiers, setSelectedIdentifiers] = useState<string[]>([]);
  const [currentScope, setCurrentScope] = useState<ScopeName>();
  const { state } = useLocation();
  const { checkRolesPermission } = useCheckRolesPermissions();
  const navigate = useNavigate();
  if (state === null) navigate("/carbon/calculations");
  const [aiValidatorClicked, setAiValidatorClicked] = useState(false);
  const [aiValidatorData, setAiValidatorData] =
    useState<AiValidatorData | null>(null);
  const [isConfirmModalVisible, { setTrue, setFalse }] = useBoolean(false);

  // sorting
  const [
    isDropdownOffsetsShown,
    { toggle: toggleDropdownOffsets, setFalse: hideDropdownOffsets },
  ] = useBoolean(false);

  const [sortOffsets, setSortOffsets] = useState<SortEmission>({
    type: "key",
    sortAlphabetically: true,
  });

  useEffect(() => {
    console.log(JSON.parse(JSON.stringify(emissionData)));
  }, [emissionData]);

  const [
    isEditCalculationModalOpen,
    { setTrue: openEditCalculationModal, setFalse: hideEditCalculationModal },
  ] = useBoolean(false);
  const [
    isAddOffsetModalVisible,
    { setTrue: showAddOffsetModal, setFalse: hideAddOffsetModal },
  ] = useBoolean(false);

  const scopes = [ScopeName.SCOPE_1, ScopeName.SCOPE_2, ScopeName.SCOPE_3];

  const scopesInformation = [
    {
      scope: ScopeName.SCOPE_1,
      title: "Scope 1 emissions",
      info: "Scope 1 covers emissions from sources that an organisation owns or controls directly - for example from burning fuel in our fleet of vehicles (if they're not electrically-powered).",
    },
    {
      scope: ScopeName.SCOPE_2,
      title: "Scope 2 emissions",
      info: "Scope 2 are emissions that a company causes indirectly when the energy it purchases and uses is produced. For example, for our electric fleet vehicles the emissions from the generation of the electricity they're powered by would fall into this category.",
    },
    {
      scope: ScopeName.SCOPE_3,
      title: "Scope 3 emissions",
      info: "Scope 3 encompasses emissions that are not produced by the company itself, and not the result of activities from assets owned or controlled by them, but by those that it's indirectly responsible for, up and down its value chain. An example of this is when we buy, use and dispose of products from suppliers. Scope 3 emissions include all sources not within the scope 1 and 2 boundaries.",
    },
  ];

  const scopesPopover = (item: any) => {
    return (
      <div className={styles.popoverScope}>
        <h2>{item.title}</h2>
        <p>{item.info}</p>
      </div>
    );
  };

  const offsetsSortOptions = [
    { name: "Name", value: "name" },
    { name: "Region", value: "region" },
  ];

  const convertedNumber = fractionalNumber(
    companyDetails.decimal_places_number
  );
  const getIdentifiers = async () => {
    try {
      const identifiers = await getAssignedIdentifiersForDefaultMetadata();
      setAssignedIdentifiers(identifiers);
      const isError = identifiers?.statusCode >= 400;
      if (isError) {
        setAssignedIdentifiers([]);
        return Promise.reject();
      }
      if (
        checkRolesPermission(["role:elevateduser"]) &&
        identifiers?.length === 1
      ) {
        getUpdatedEmissions(identifiers);
      }
    } catch {
      message.error("Error while getting identifiers!");
    }
  };
  useEffect(() => {
    localStorage.removeItem("identifiersValues");
    calculatorStore.resetState();
    const id = (state as CalculationState).calcId;
    carbonStore.fetchSheets();
    calculatorStore.getCalculationById(id);
    getIdentifiers();
    loadCompanyInfo();
  }, []);

  useEffect(() => {
    console.log(JSON.parse(JSON.stringify(emissionData)));
  }, [emissionData]);

  useEffect(() => {
    const disposer = autorun(() => {
      setEmissionData(calculatorStore.emissions || []);

      setOffsets(calculatorStore.offsets || []);

      Object.keys(calculatorStore.calculation).length !== 0 &&
        setCalculation({
          ...calculatorStore.calculation,
          attribution_factor:
            calculatorStore.calculation.attribution_factor || 100,
        });
    });

    return () => {
      disposer();
    };
  }, []);

  const getConversionFactor = async () => {
    const factor = await doConversion(1, "kg", companyDetails.emissions_unit);
    setConversionFactor(factor);
  };

  useEffect(() => {
    companyDetails.emissions_unit && getConversionFactor();
  }, [companyDetails]);

  useEffect(() => {
    calculation && calculatorStore.getCalculationItems();
  }, [
    calculatorStore.calculationItemsFilter,
    calculatorStore.selectedIdentifiers,
  ]);

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    }
  };

  const handleSort = (scope: ScopeName, sortEmissions: SortEmission) => {
    setEmissionData((scopeData: Emission[]) => {
      const filterData = scopeData.filter((item) => item.scope !== scope);
      const newData = scopeData
        .slice()
        .filter((item) => item.scope === scope)
        .sort((a: Emission, b: Emission) => {
          if (a.factor_data && b.factor_data) {
            const factorA =
              a.factor_data[sortEmissions.type as keyof CustomManualFactor];
            const factorB =
              b.factor_data[sortEmissions.type as keyof CustomManualFactor];

            if (factorA !== undefined && factorB !== undefined) {
              if (sortEmissions.sortAlphabetically) {
                return factorA > factorB ? 1 : -1;
              } else {
                return factorA < factorB ? 1 : -1;
              }
            }
          } else {
          }
          // Handle case where factor_data is undefined for either object
          return 0; // or handle differently based on your sorting logic
        });
      return [...newData, ...filterData];
    });
  };

  const handleSortOffsets = (
    setScopeData: React.Dispatch<React.SetStateAction<Offset[]>>
  ) => {
    setScopeData((scopeData: Offset[]) => {
      const newData = scopeData.slice().sort((a: Offset, b: Offset) => {
        const aStatement = a[sortOffsets.type as keyof Offset] || "";
        const bStatement = b[sortOffsets.type as keyof Offset] || "";
        if (sortOffsets.sortAlphabetically) {
          return aStatement > bStatement ? 1 : -1;
        } else {
          return aStatement < bStatement ? 1 : -1;
        }
      });
      return [...newData];
    });
  };
  const filterEmissions = (emissions: Emission[]) => {
    return isDeleted
      ? emissions.filter((item) => item.archived)
      : emissions.filter((item) => !item.archived);
  };
  const filterOffsets = () => {
    return isDeleted
      ? offsets.filter((item) => item.archived)
      : offsets.filter((item) => !item.archived);
  };
  const handleEditConsumptionAmount = (
    data: Emission,
    consumptionAmount: number
  ) => {
    if (!calculation) return;
    const emissions = calculatorStore.editConsumptionAmount(
      data._id?.$oid!,
      Number(consumptionAmount)
    );

    const emission = emissions.find((item) => item._id?.$oid == data._id?.$oid);

    if (emission) {
      calculatorStore.editCalculationEntry(emission);
    }
  };

  const handleEditCustomName = (data: Emission, customName: string) => {
    if (!calculation) return;
    const emissions = calculatorStore.editCustomName(
      data._id?.$oid!,
      customName
    );
    const emission = emissions.find((item) => item._id?.$oid == data._id?.$oid);
    const payload = {
      _id: emission?._id,
      calculation_id: emission?.calculation_id,
      custom_name: emission?.custom_name,
    } as Emission;
    if (emission) {
      calculatorStore.editPartialCalculationEntry(payload);
    }
  };

  const handleEditCalculation = ({
    name,
    description,
    date_start,
    date_end,
    regions,
    attribution_factor,
    isCheckedFilters,
    isDrilldownEnabled,
    drilldownFilters,
  }: EditCalculationData) => {
    const payload = {
      ...calculation,
      name,
      description,
      date_start,
      date_end,
      regions,
      attribution_factor,
      isDrilldownEnabled,
      drilldownFilters,
    };

    const partialPayload = {
      _id: calculation?._id,
      name,
      description,
      date_start,
      date_end,
      regions,
      attribution_factor,
      isDrilldownEnabled,
      drilldownFilters,
    };

    const isModified =
      findModifiedFields(calculation, payload).some(
        (v) => v == "attribution_factor"
      ) || !!isCheckedFilters;

    isModified
      ? calculatorStore
          .updateCalculationJob(payload as ICalculation, !!isCheckedFilters)
          .then((jobId) => {
            fetchDataWithRetry(jobId).then((result) => {
              if (result?.resultSummary) {
                const isError = result?.resultSummary?.statusCode >= 400;
                if (isError) {
                  message.error(result?.resultSummary?.body);
                  return Promise.reject();
                }
                result.resultSummary?.message &&
                  message.success(result?.resultSummary?.message);
              } else {
                return Promise.reject();
              }
              navigate(`/carbon/calculations/${encodeUrlName(name)}`, {
                state: calculation?._id?.$oid,
              });
            });
          })
      : calculatorStore
          .partialUpdateCalculation(partialPayload as ICalculation)
          .then(() => {
            navigate(`/carbon/calculations/${encodeUrlName(name)}`, {
              state: calculation?._id?.$oid,
            });
          });

    hideEditCalculationModal();
  };
  const findModifiedFields = (oldObj: any, newObj: any) => {
    const modifiedFields = [];

    for (const key in newObj) {
      if (oldObj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
        if (oldObj[key] !== newObj[key]) {
          modifiedFields.push(key);
        }
      }
    }

    return modifiedFields;
  };

  // this saves the calcuation entries. total emissions is not calculated here. it is already calculated.
  const addEmission = (newEmission: Emission[] | Emission) => {
    if (calculation) {
      const newEmissionsData = Array.isArray(newEmission)
        ? newEmission.map((v) => ({
            ...v,
            item_type: CalculationEntryType.EMISSIONS,
          }))
        : [{ ...newEmission, item_type: CalculationEntryType.EMISSIONS }];
      calculatorStore.saveCalculationEntries(newEmissionsData);
    } else {
      console.error("Calculation is undefined");
    }
  };

  const editEmission = (data: Emission) => {
    calculatorStore.editCalculationEntry(data);
  };
  const onMarkAsEstimated = (data: Emission) => {
    calculatorStore.editCalculationEntry(data);
  };
  const addEmptyLine = (scope: ScopeName) => {
    scope === ScopeName.OFFSETS
      ? setOffsets((prevState) => [
          ...prevState,
          {
            key: uuidv4(),
            scope: ScopeName.OFFSETS,
          } as Offset,
        ])
      : setEmissionData((prevState) => [
          ...prevState,
          {
            key: uuidv4(),
            manual: true,
            scope: scope,
          } as ManualEmission,
        ]);
  };
  const onDeleteEmptyEmission = (data: Emission) => {
    setEmissionData((prevState) => {
      return prevState.filter((v) => v.key !== data.key);
    });
  };

  const isDisabledEmptyLineBtn =
    !!emissionData.find((emission) => !emission?.factor_data?.name) ||
    !!offsets.find((offset) => !offset.name);

  const onClickDeleteEmissionInCalculator = (emission: Emission) => {
    try {
      calculatorStore.editCalculationEntry({ ...emission, archived: true });
    } catch (error) {
      console.log("Error while removing row", error);
      message.error("Something went wrong!.");
    }
  };

  const handleDeleteEmissionDeletedItems = (data: Emission) => {
    try {
      calculatorStore.deleteDeletedCalcItem(data);
    } catch (error) {
      console.log("Error while removing row", error);
      message.error("Something went wrong!.");
    }
  };

  const onClickUndoEmission = (emission: Emission) => {
    const newData = emissionData.map((v) => {
      if (v._id?.$oid == emission._id?.$oid) {
        return { ...v, archived: false };
      }
      return v;
    });
    setEmissionData(newData);
  };
  const onRestoreEmission = (data: Emission) => {
    try {
      calculatorStore.editCalculationEntry({ ...data, archived: false });
    } catch (error) {
      console.log("Error while restoring row", error);
      message.error("Something went wrong!.");
    }
  };

  const handleAddOffset = (offset: Offset) => {
    if (calculation) {
      const newOffsetsData = [{ ...offset, item_type: "offset" }];

      calculatorStore.saveCalculationEntries(newOffsetsData as Offset[]);
    } else {
      console.error("Calculation is undefined");
    }
    hideAddOffsetModal();
  };

  const editOffset = (dataOffset: Offset) => {
    calculatorStore.editCalculationEntry(dataOffset);
  };
  const getUpdatedEmissions = async (identifiers: string[]) => {
    try {
      setSelectedIdentifiers(identifiers);
      calculatorStore.changeSelectedIdentifiers(identifiers);
    } catch (err) {
      console.error(err);
    }
  };
  const onChangeIdentifiers = (value: string[]) => {
    getUpdatedEmissions(value);
  };
  const onDeleteEmptyOffset = (data: Offset) => {
    setOffsets((prevState) => {
      return prevState.filter((v) => v.key !== data.key);
    });
  };
  const handleDeleteOffsetDeletedItems = (offset: Offset) => {
    try {
      calculatorStore.deleteDeletedCalcItem(offset);
      setOffsets((prev) =>
        prev.filter((item) => item._id?.$oid !== offset._id?.$oid)
      );
    } catch (error) {
      console.log("Error while removing row", error);
      message.error("Something went wrong!.");
    }
  };

  const handleDeleteOffsetCalculator = (offset: Offset) => {
    try {
      const newData = offsets.map((v) => {
        if (v._id?.$oid === offset._id?.$oid) {
          return { ...v, archived: true };
        }
        return v;
      });
      calculatorStore.editCalculationEntry({ ...offset, archived: true });
      setOffsets(newData);
    } catch (error) {
      console.log("Error while removing row", error);
      message.error("Something went wrong!.");
    }
  };

  const onRestoreOffset = (offset: Offset) => {
    try {
      const newData = offsets.map((v) => {
        if (v._id?.$oid == offset._id?.$oid) {
          return { ...v, archived: false };
        }
        return v;
      });
      calculatorStore.editCalculationEntry({ ...offset, archived: false });
      setOffsets(newData);
    } catch (error) {
      console.log("Error while restoring row", error);
      message.error("Something went wrong!.");
    }
  };

  const onClickUndoOffset = (offset: Offset) => {
    const newData = offsets.map((v) => {
      if (v._id?.$oid == offset._id?.$oid) {
        return { ...v, archived: false };
      }
      return v;
    });
    calculatorStore.editCalculationEntry({
      ...offset,
      archived: false,
    });
    setOffsets(newData);
  };

  const onRestoreAllDeletedItems = () => {
    calculatorStore.restoreAllRecords();
  };

  const onDeleteAllDeletedItems = () => {
    calculatorStore.deleteAllRecords();
  };

  const producedTotal =
    calculation?.total_calculate?.scope1_total! +
    calculation?.total_calculate?.scope2_total! +
    calculation?.total_calculate?.scope3_total!;

  function countPercent(number: number, sum: number | 0) {
    const total = numeral(number);
    if (+sum) {
      return total.divide(+sum).multiply(100).value();
    }
    return 0;
  }
  const handleOpenAddEmission = (scope: ScopeName) => {
    setIsModalVisible(true);
    setCurrentScope(scope);
  };
  const handleDeleteCalculation = () => {
    calculationStore.deleteCalculation(
      (state as CalculationState).calcId as string
    );
    navigate("/carbon/calculations");
    if ((state as CalculationState).groupId?.length) {
      const group: CalculationGroup =
        calculationGroupsStore.calculationGroups.find(
          (v) => v._id?.$oid == (state as CalculationState).groupId
        ) || ({} as CalculationGroup);
      const calculations = group?.calculationsList.filter(
        (v) => v !== (state as CalculationState).calcId
      );

      group &&
        calculationGroupsStore.updateCalculationGroup({
          ...group,
          calculationsList: calculations || [],
        });
    }
  };

  const infoConfirmModal = {
    title: `Are you sure you want to delete ${calculation?.name}?`,
    description: "This action cannot be undone",
    confirmText: "Delete permanently",
    cancelText: "Continue without deleting",
  };

  if (!calculation) return <Spin className={styles.calculatorLoader} />;

  const editCalculationData = {
    name: calculation.name,
    description: calculation.description,
    date_start: calculation.date_start,
    date_end: calculation.date_end,
    regions: calculation.regions,
    attribution_factor: calculation.attribution_factor,
    isDrilldownEnabled: calculation?.isDrilldownEnabled || false,
    drilldownFilters: calculation?.drilldownFilters,
    isLocked: calculation?.isLocked,
  };
  const getScopeClassName = (scope: ScopeName) => {
    if (scope === ScopeName.SCOPE_1) {
      return styles.scopePanel1;
    } else if (scope === ScopeName.SCOPE_2) {
      return styles.scopePanel2;
    } else if (scope === ScopeName.SCOPE_3) {
      return styles.scopePanel3;
    }
  };

  const handleRefreshJob = async () => {
    try {
      const jobId = await calculationStore.refreshCalculations_job(
        () => calculatorStore.getCalculationItems(),
        {
          filter: { _id: { $oid: calculation._id?.$oid } },
        }
      );
      calculatorStore.changeLoadingState(true);
      const result = await fetchDataWithRetry(jobId);
      const resultSummary = result?.resultSummary || "";

      if (resultSummary) {
        const isError = resultSummary?.statusCode >= 400;
        if (isError) {
          message.error(resultSummary?.body);
          return Promise.reject();
        }
        resultSummary?.message && message.success(resultSummary?.message);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      message.error("Error while refreshing calculation!");
    } finally {
      calculatorStore.changeLoadingState(false);
    }
  };

  const identifierSelector = (
    <div className={CalculationStyles.select} style={{ width: "300px" }}>
      <Select
        value={selectedIdentifiers}
        disabled={
          checkRolesPermission(["role:elevateduser"]) &&
          assignedIdentifiers?.length === 1
        }
        showSearch
        allowClear
        showArrow
        placeholder="Select Identifier"
        filterOption={(input: string, option: any) =>
          ((option?.label as string) ?? "")
            .toLowerCase()
            .includes(input?.toLowerCase())
        }
        options={assignedIdentifiers?.map((item: any) => ({
          value: item,
          label: item?.toString(),
        }))}
        onChange={(value: string[]) => onChangeIdentifiers(value)}
        mode="multiple"
      />
    </div>
  );
  return (
    <>
      {calculatorStore.loadingCalculation && (
        <Spin className={styles.calculatorLoader} />
      )}
      <PageWrap
        className={
          calculatorStore.loadingCalculation && styles.calculatorContent
        }
      >
        <ReadinessScoreComponent
          onClick={async () => {
            if (!aiValidatorClicked && emissionData[0]) {
              let result = await validateGhgCalculation(emissionData[0], true);
              console.log(result, "result success return");
              setAiValidatorData(result);
              setAiValidatorClicked(true);
            }
          }}
          data={aiValidatorData}
        />
        <Row justify="space-between" gutter={[32, 32]}>
          <Col flex={"auto"}>
            <Row
              style={{ height: "30%" }}
              justify="space-between"
              align="middle"
            >
              <Col>
                <div className={styles.calculationName}>
                  {calculation.name}
                  {calculation.date_start && calculation.date_end && (
                    <span className={styles.calculationPeriod}>
                      {moment(
                        (calculation.date_start as { $date: string })?.$date ||
                          (calculation.date_start as string)
                      ).format("YYYY-MM-DD")}{" "}
                      -{" "}
                      {moment(
                        (calculation.date_end as { $date: string })?.$date ||
                          (calculation.date_start as string)
                      ).format("YYYY-MM-DD")}
                    </span>
                  )}
                </div>
                <div className={styles.calculationDesc}>
                  {calculation.description}
                </div>
              </Col>
              {!isDeleted &&
                !!assignedIdentifiers.length &&
                !checkRolesPermission(["role:elevateduser"]) &&
                identifierSelector}
            </Row>

            <Row className={styles.scopeBlock} gutter={2} align="middle">
              <Col
                flex={
                  countPercent(
                    calculation.total_calculate?.scope1_total!,
                    producedTotal || 0
                  ) || 1
                }
              >
                <div className={styles.scopeNum}>Scope 1</div>
                <div className={styles.scopeValue}>
                  {convertedNumber(
                    calculation.total_calculate?.scope1_total! *
                      conversionFactor
                  )}{" "}
                  {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                </div>
                <div className={`${styles.scopeRange} ${styles.scopeRange1}`}>
                  Scope 1 -{" "}
                  {
                    +countPercent(
                      calculation.total_calculate?.scope1_total!,
                      producedTotal || 0
                    )
                      .toFixed(companyDetails.decimal_places_number)
                      .replace(/\.00$/, "")
                  }
                  %
                </div>
              </Col>
              <Col
                flex={
                  countPercent(
                    calculation.total_calculate?.scope2_total!,
                    producedTotal || 0
                  ) || 1
                }
              >
                <div className={styles.scopeNum}>Scope 2</div>
                <div className={styles.scopeValue}>
                  {convertedNumber(
                    calculation.total_calculate?.scope2_total! *
                      conversionFactor
                  )}{" "}
                  {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                </div>
                <div className={`${styles.scopeRange} ${styles.scopeRange2}`}>
                  Scope 2 -{" "}
                  {
                    +countPercent(
                      calculation.total_calculate?.scope2_total!,
                      producedTotal || 0
                    )
                      .toFixed(companyDetails.decimal_places_number)
                      .replace(/\.00$/, "")
                  }
                  %
                </div>
              </Col>
              <Col
                flex={
                  countPercent(
                    calculation.total_calculate?.scope3_total!,
                    producedTotal || 0
                  ) || 1
                }
              >
                <div className={styles.scopeNum}>Scope 3</div>
                <div className={styles.scopeValue}>
                  {convertedNumber(
                    calculation.total_calculate?.scope3_total! *
                      conversionFactor
                  )}{" "}
                  {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
                </div>
                <div className={`${styles.scopeRange} ${styles.scopeRange3}`}>
                  Scope 3 -{" "}
                  {
                    +countPercent(
                      calculation.total_calculate?.scope3_total!,
                      producedTotal || 0
                    )
                      .toFixed(companyDetails.decimal_places_number)
                      .replace(/\.00$/, "")
                  }
                  %
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            style={{
              minWidth: "25%",
            }}
          >
            <Row
              style={{
                height: checkRolesPermission(["role:elevateduser"])
                  ? "84px"
                  : "30%",
                display: "flex",
                alignItems: "center",
              }}
              justify="space-between"
            >
              {checkRolesPermission(["role:elevateduser"]) ? (
                !isDeleted &&
                !!assignedIdentifiers?.length &&
                checkRolesPermission(["role:elevateduser"]) &&
                identifierSelector
              ) : !isDeleted ? (
                <>
                  <Col>
                    <StyledButton
                      disabled={calculation.isLocked}
                      type="primary"
                      ghost
                      bgcolor="transparent"
                      bordercolor="#fff"
                      hoverbgcolor="#a68dfb"
                      className={`margin-5`}
                      onClick={() => handleRefreshJob()}
                    >
                      <ReloadOutlined />
                      <span>Refresh</span>
                    </StyledButton>
                    <StyledButton
                      type="primary"
                      ghost
                      bgcolor="transparent"
                      bordercolor="#fff"
                      hoverbgcolor="#a68dfb"
                      className={`margin-5`}
                      onClick={openEditCalculationModal}
                    >
                      <EditFilled />
                      <span>Edit calculation</span>
                    </StyledButton>
                    <StyledButton
                      disabled={calculation.isLocked}
                      type="primary"
                      ghost
                      bgcolor="transparent"
                      bordercolor="#fff"
                      hoverbgcolor="#a68dfb"
                      className={`margin-5`}
                      onClick={setTrue}
                    >
                      <DeleteFilled />
                      <span> Delete</span>
                    </StyledButton>
                  </Col>

                  <ConfirmModal
                    onConfirm={handleDeleteCalculation}
                    visible={isConfirmModalVisible}
                    setFalse={setFalse}
                    infoConfirmModal={infoConfirmModal}
                  />
                </>
              ) : (
                <Col>
                  <StyledButton
                    disabled={calculation.isLocked}
                    type="primary"
                    ghost
                    bgcolor="transparent"
                    bordercolor="#fff"
                    hoverbgcolor="#a68dfb"
                    className={`margin-5`}
                    onClick={onRestoreAllDeletedItems}
                  >
                    <RedoOutlined rotate={-90} />{" "}
                    <span> Restore all elements</span>
                  </StyledButton>
                  <StyledButton
                    disabled={calculation.isLocked}
                    type="primary"
                    ghost
                    bgcolor="transparent"
                    bordercolor="#fff"
                    hoverbgcolor="#a68dfb"
                    className={`margin-5`}
                    onClick={onDeleteAllDeletedItems}
                  >
                    <DeleteFilled />
                    <span> Delete all</span>
                  </StyledButton>
                </Col>
              )}
            </Row>
            <Row className={styles.totalBlock} gutter={2}>
              <Col flex={1}>
                <div className={styles.totalTitle}>Total</div>
                <Tooltip
                  title={`Attribution factor (${calculation.attribution_factor}%)`}
                >
                  <div className={styles.totalValue}>
                    {convertedNumber(
                      calculation.total_calculate?.all_total! * conversionFactor
                    )}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO
                    <sub>2</sub>e
                  </div>
                </Tooltip>
                <div className={styles.totalDesc}>
                  <span className={styles.totalDescName}>Produced</span>
                  <span>
                    {convertedNumber(
                      (calculation.total_calculate?.scope1_total! +
                        calculation.total_calculate?.scope2_total! +
                        calculation.total_calculate?.scope3_total!) *
                        conversionFactor
                    )}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO
                    <sub>2</sub>e
                  </span>
                </div>
                <div className={styles.totalDesc}>
                  <span className={styles.totalDescName}>Offsetted</span>
                  <span>
                    {convertedNumber(
                      calculation.total_calculate?.offsetted_total! *
                        conversionFactor
                    )}{" "}
                    {getEmissionsUnit(companyDetails.emissions_unit)} CO
                    <sub>2</sub>e
                  </span>
                </div>
                {calculation.attribution_factor !== 100 && (
                  <div className={styles.totalDesc}>
                    <span className={styles.totalDescName}>Unattributed</span>
                    <span>
                      {convertedNumber(
                        calculation.total_calculate?.unattributed_total! *
                          conversionFactor
                      )}{" "}
                      {getEmissionsUnit(companyDetails.emissions_unit)} CO
                      <sub>2</sub>e
                    </span>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Collapse
          defaultActiveKey={
            scopes.find(
              (item) => emissionData.filter((v) => v.scope === item)?.length
            ) ||
            (filterOffsets()?.length && ScopeName.OFFSETS) ||
            ScopeName.SCOPE_1
          }
          expandIcon={({ isActive }) => (
            <div className={styles.expandContainer}>
              <CaretRightFilled
                className={styles.expandIcon}
                rotate={isActive ? 90 : 0}
              />
              <span className={styles.expandText}>
                {isActive ? "Collapse table" : "Expand table"}
              </span>
            </div>
          )}
          expandIconPosition="end"
          className={styles.scopeCollapse}
          bordered={false}
          ghost
        >
          {scopes.map((scope) => (
            <Fragment key={scope}>
              <Collapse.Panel
                className={`${styles.scopePanel} ${getScopeClassName(scope)}`}
                header={
                  <div className={styles.panelHeader}>
                    Scope {getScopeNumber(scope)}
                    <Popover
                      overlayClassName="popoverContent"
                      content={() =>
                        scopesPopover(
                          scopesInformation.find((item) => item.scope === scope)
                        )
                      }
                      placement="right"
                      autoAdjustOverflow
                      arrowPointAtCenter
                    >
                      <QuestionCircleFilled className={styles.popoverIcon} />
                    </Popover>
                    <span className={styles.recordsCount}>
                      (
                      {
                        filterEmissions(
                          emissionData.filter((item) => item.scope === scope)
                        ).length
                      }{" "}
                      records)
                    </span>
                  </div>
                }
                extra={
                  <SortDropdownCalculator
                    onSort={(sortEmission) => handleSort(scope, sortEmission)}
                  />
                }
                key={scope}
              >
                {!isDeleted ? (
                  <EditableCalculationTable
                    isCalculationLocked={calculation && calculation.isLocked}
                    isIndicatorTypeShown={scope === ScopeName.SCOPE_2}
                    data={filterEmissions(
                      emissionData.filter(
                        (emission) => emission.scope === scope
                      )
                    )}
                    attributionFactor={calculation.attribution_factor}
                    editEmission={editEmission}
                    addEmission={addEmission}
                    onSaveConsumptionAmount={handleEditConsumptionAmount}
                    onSaveCustomName={handleEditCustomName}
                    onDelete={onClickDeleteEmissionInCalculator}
                    onDeleteEmptyLine={onDeleteEmptyEmission}
                    onClickUndo={onClickUndoEmission}
                    handleMarkAsEstimated={onMarkAsEstimated}
                    emissionsUnit={getEmissionsUnit(
                      companyDetails.emissions_unit
                    )}
                    conversionFactor={conversionFactor}
                    scope={scope}
                  />
                ) : (
                  <DeletedItemsTable
                    isCalculationLocked={calculation && calculation.isLocked}
                    data={filterEmissions(
                      emissionData.filter(
                        (emission) => emission.scope === scope
                      )
                    )}
                    onRestore={onRestoreEmission}
                    onDelete={handleDeleteEmissionDeletedItems}
                    onSaveTableData={(
                      data: Emission,
                      consumptionAmount: number
                    ) => handleEditConsumptionAmount(data, consumptionAmount)}
                    emissionsUnit={getEmissionsUnit(
                      companyDetails.emissions_unit
                    )}
                    conversionFactor={conversionFactor}
                  />
                )}
              </Collapse.Panel>
              {!isDeleted && !checkRolesPermission(["role:elevateduser"]) && (
                <div>
                  <StyledButton
                    type="primary"
                    onClick={() => handleOpenAddEmission(scope)}
                    disabled={calculation.isLocked}
                  >
                    <PlusCircleOutlined />
                    Add Emission
                  </StyledButton>

                  <StyledButton
                    type="primary"
                    onClick={() => addEmptyLine(scope)}
                    disabled={isDisabledEmptyLineBtn || calculation.isLocked}
                  >
                    <PlusCircleOutlined /> Add Empty Line
                  </StyledButton>
                </div>
              )}
            </Fragment>
          ))}
          <Collapse.Panel
            className={`${styles.scopePanel} ${styles.scopePanel3}`}
            header={
              <div className={styles.panelHeader}>
                Offsets{" "}
                <Popover
                  overlayClassName="popoverContent"
                  autoAdjustOverflow
                  content={
                    <div className={styles.popoverScope}>
                      <h2>Offsets</h2>
                      <p>Offsets</p>
                    </div>
                  }
                  placement="right"
                  arrowPointAtCenter
                >
                  <QuestionCircleFilled className={styles.popoverIcon} />
                </Popover>
                <span className={styles.recordsCount}>
                  ({filterOffsets()?.length || 0} records)
                </span>
              </div>
            }
            extra={
              <Button
                type="text"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Dropdown
                  overlay={
                    <SortDropdown
                      sorting={sortOffsets}
                      changeSorting={setSortOffsets}
                      options={offsetsSortOptions}
                      onConfirm={() => {
                        handleSortOffsets(setOffsets);
                        hideDropdownOffsets();
                      }}
                    />
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                  onVisibleChange={toggleDropdownOffsets}
                  visible={isDropdownOffsetsShown}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <SwapOutlined rotate={90} />
                    <span className={styles.expandText}>Sort</span>
                  </a>
                </Dropdown>
              </Button>
            }
            key={"offsets"}
          >
            <EditableCalculationOffsetsTable
              isCalculationLocked={calculation && calculation.isLocked}
              data={filterOffsets()}
              editOffset={editOffset}
              addOffset={handleAddOffset}
              onDelete={
                isDeleted
                  ? handleDeleteOffsetDeletedItems
                  : handleDeleteOffsetCalculator
              }
              onRestore={onRestoreOffset}
              isDeleted={isDeleted}
              onDeleteEmptyOffset={onDeleteEmptyOffset}
              onClickUndo={onClickUndoOffset}
              emissionsUnit={getEmissionsUnit(companyDetails.emissions_unit)}
              conversionFactor={conversionFactor}
            />
          </Collapse.Panel>

          {!isDeleted && !checkRolesPermission(["role:elevateduser"]) && (
            <div>
              <StyledButton
                type="primary"
                onClick={showAddOffsetModal}
                disabled={calculation.isLocked}
              >
                <PlusCircleOutlined /> Add Offset
              </StyledButton>
              <StyledButton
                type="primary"
                disabled={isDisabledEmptyLineBtn || calculation.isLocked}
                onClick={() => addEmptyLine(ScopeName.OFFSETS)}
              >
                <PlusCircleOutlined /> Add Empty Line
              </StyledButton>
            </div>
          )}
        </Collapse>
        <AddOffset
          visible={isAddOffsetModalVisible}
          data={{} as Offset}
          modalName="Add offset"
          onCancel={hideAddOffsetModal}
          onConfirm={handleAddOffset}
        />
        <AddEmissionsModal
          visible={isModalVisible}
          scopeName={currentScope!}
          addEmission={addEmission}
          onClose={() => setIsModalVisible(false)}
        />
        <EditCalculationModal
          data={editCalculationData}
          isOpen={isEditCalculationModalOpen}
          onClose={hideEditCalculationModal}
          onConfirm={handleEditCalculation}
        />
      </PageWrap>
    </>
  );
});

export default Calculator;
