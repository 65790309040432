import React, { useEffect, useState } from "react";
import {
  FormWrap,
  FormWrapper,
  PageWrap,
  StyledButton,
  StyledSteps,
} from "../../shared/commonStyles";
import { Col, Form, Progress, Row, Space, Spin, message } from "antd";
import { useNavigate, useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SelectKPI } from "./components/SelectKPI";
import { GeneralInformation } from "./components/GeneralInformation";
import { getKpiMetric } from "../../services/kpi";
import {
  fetchRecordData,
  getDataSheets,
  getDataSheetsWithPagination,
} from "../../services/dataSheet";
import { FooterButtons } from "./components/FooterButtons";
import { EmailTemplate } from "./components/EmailTemplate";
import { arrayMoveImmutable } from "array-move";
import { SurveyOrder } from "./components/SurveyOrder";
import { Success } from "./components/Success";
import {
  addSurveys,
  getSurveyById,
  updateSurveys,
} from "../../services/surveys";
import KpiData from "../kpi";

export const CreateKPIsSurvey = () => {
  const { survey_id: surveyId } = useParams();
  const [loadingKPIs, setLoadingKPIs] = useState(false);
  const sampleEmailBody = `Hello {FULL_NAME},<br><br>We are conducting the <b>{SURVEY_NAME}</b> survey and would like your input. Please follow {SIGNUP_LINK} to our data management platform, <a href="http://Hydrus.ai target=__blank">Hydrus.ai</a>, where you can complete the survey. If you have any questions about this process, please don’t hesitate to reach out to your administrator.<br><br>
  Thanks,<br><br>Administrator`;
  const [surveyData, setSurveyData] = useState({
    name: "",
    description: "",
    status: true,
    email_subject: "",
    reminder_interval: "op1",
    email_type: "Primary",
    email_reminder_subject: "",
    email_body: sampleEmailBody,
    email_reminder_body: "",
  } as any);
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [kpisList, setKpisList] = useState<any[]>([]);
  const [selectedKPIs, setSelectedKPIs] = useState<any[]>([]);
  const navigate = useNavigate();
  const [action, setAction] = useState("create");

  const loadKpiData = async () => {
    const surveyOrders =
      surveyData.survey_order && JSON.parse(surveyData.survey_order);

    if (surveyOrders) {
      setKpisList((prev) =>
        prev?.map((dataSheet: any) => {
          const newKPIData = dataSheet.kpiData.map((v: any) => {
            if (surveyOrders.find((item: any) => item.id === v._id.$oid)) {
              return {
                ...v,
                checked: true,
              };
            } else {
              return v;
            }
          });

          return {
            ...dataSheet,
            kpiData: newKPIData,
            checked: newKPIData.every((v: any) => v.checked),
          };
        })
      );

      const newSelectedKPIs = kpisList
        .flatMap((v) => v.kpiData)
        .filter((v) => surveyOrders.find((item: any) => item.id === v._id.$oid))
        .map((v, index) => ({ ...v, index }));

      setSelectedKPIs(newSelectedKPIs);
    }
  };

  const loadSurveyData = async () => {
    try {
      setLoadingKPIs(true);
      const surveyData = await getSurveyById(surveyId);
      if (surveyData) {
        const initData = {
          ...surveyData,
          status: surveyData?.status === "ACTIVE" ? true : false,
          email_type: "Primary",
          reminder_interval:
            surveyData.reminder_interval === null
              ? "op1"
              : surveyData.reminder_interval === 1000000
              ? surveyData.reminder_interval
              : "recurring_reminder",
          recurring_reminder_val:
            surveyData.reminder_interval != 1000000
              ? surveyData.reminder_interval
              : "",
        };
        form.setFieldsValue(initData);
        setSurveyData(initData);
      } else {
        message.error("Survey not found");
        navigate(`/management/collect-data`);
      }
    } catch (e) {
      message.error("Something went wrong while loading survey!");
    } finally {
      await loadKpiData();
      setLoadingKPIs(false);
    }
  };

  useEffect(() => {
    if (surveyId && kpisList) {
      setAction("update");
      loadSurveyData();
    }
  }, [surveyId, kpisList?.length]);

  const onConfirmSurvey = () => {
    form
      .validateFields()
      .then(async () => {
        try {
          setLoadingKPIs(true);
          const {
            name,
            description,
            status,
            email_body,
            email_reminder_body,
            reminder_interval,
            recurring_reminder_val,
            email_reminder_subject,
            email_subject,
          } = surveyData;
          const statusText = status ? "ACTIVE" : "INACTIVE";
          const dataIds = selectedKPIs.map((item) => ({
            id: item._id["$oid"],
            survey_type: "kpi",
            sheet_id: item.sheet_id.$oid,
          }));
          const emailData = email_body?.replace("{SURVEY_NAME}", name);
          const reminderEmailData = email_reminder_body?.replace(
            "{SURVEY_NAME}",
            name
          );
          const reminder_interval_final =
            reminder_interval === "recurring_reminder"
              ? recurring_reminder_val
              : reminder_interval === "op1"
              ? null
              : 1000000;
          const payload = {
            ...(action === "update" ? { id: surveyData.id } : {}),
            survey_type: "kpi",
            name,
            description,
            status: statusText,
            survey_order: JSON.stringify(dataIds),
            email_body: emailData,
            email_subject,
            email_reminder_subject,
            email_reminder_body: reminderEmailData,
            reminder_interval: reminder_interval_final,
          };
          const response =
            action === "update"
              ? await updateSurveys(payload)
              : await addSurveys(payload);
          if (response) {
            form.resetFields();
            setSurveyData(
              action === "update"
                ? response.data.updateSurveyManagement
                : response.data.createSurveyManagement
            );
            setStep(step + 1);
          }
        } catch (e) {
          message.error(
            `Error while ${
              action === "update" ? "updating" : "creating"
            } survey.`
          );
        } finally {
          setLoadingKPIs(false);
        }
      })
      .catch((error) => console.log("error form in survey", error));
  };
  const onClickNext = async () => {
    try {
      if (step === 4) {
        await onConfirmSurvey();
      } else {
        await form.validateFields();
        setStep(step + 1);
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const onChangeFieldsData = () => {
    const data = form.getFieldsValue(true);
    setSurveyData(data);
  };

  const onClickBack = async () => {
    step === 1 ? navigate("/management/collect-data") : setStep(step - 1);
  };
  const getKpisData = async () => {
    // setLoadingKPIs(true);
    // const kpiForDatasheets = await getKpiMetric();

    // const sheetIds = kpiForDatasheets.map((item: any) => item.sheetId);
    // const uniqSheetIds = [...new Set(sheetIds)];
    // const payload = {
    //   filter: { _id: { $oid: uniqSheetIds } },
    // };
    // const dataSheets = await getDataSheets(payload);
    // console.log(dataSheets);

    // const KPIDatasheets = dataSheets.filter(
    //   (item: DataSheet) => item.is_kpi_data
    // );

    // setKpisList(KPIDatasheets);
    setLoadingKPIs(true);
    let datasheetsQuery = {
      skip: 0,
      sort: { _id: 1 },
      filter: {
        group: "org:Hydrus",
        archive: false,
        is_kpi_data: true,
      },
    };

    let recordsQuery = {
      skip: 0,
      record_types: {
        include: [],
        exclude: ["archived", "imported_pending"],
      },
    };

    let datasheets = await getDataSheetsWithPagination(datasheetsQuery);

    let fetchRecordsPromises = datasheets.data.map((datasheet: any) => {
      let currentQuery = { ...recordsQuery, sheet_id: datasheet._id.$oid };

      return fetchRecordData(currentQuery).then((records) => {
        if (records.filteredCount > 0) {
          return { ...datasheet, kpiData: records.data };
        }
        return null;
      });
    });

    let datasheetsWithRecords = await Promise.all(fetchRecordsPromises);

    datasheetsWithRecords = datasheetsWithRecords.filter(
      (datasheet) => datasheet !== null
    );

    setKpisList(datasheetsWithRecords);
    setLoadingKPIs(false);
  };

  useEffect(() => {
    getKpisData();
  }, []);

  const onSortSurveyOrder = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(selectedKPIs as any),
        oldIndex,
        newIndex
      )?.filter((el) => !!el);
      const indexKeyUpdate = newData.map((item: any, index) => ({
        ...item,
        index,
      }));
      setSelectedKPIs(indexKeyUpdate);
    }
  };

  const handleChangeSelectionKPIs = (
    checked: boolean,
    itemId: string,
    type: "dataSheet" | "kpi"
  ) => {
    const newArray = kpisList?.map((dataSheet: any) => {
      if (type === "dataSheet" && dataSheet._id.$oid === itemId) {
        return {
          ...dataSheet,
          checked: checked,
          kpiData: dataSheet.kpiData.map((v: any) => ({
            ...v,
            checked: checked,
          })),
        };
      } else if (type === "kpi") {
        const newKPIData = dataSheet.kpiData.map((v: any) =>
          v._id.$oid === itemId
            ? {
                ...v,
                checked: checked,
              }
            : v
        );
        return {
          ...dataSheet,
          kpiData: newKPIData,
          checked: newKPIData.every((v: any) => v.checked),
        };
      } else return dataSheet;
    });
    setKpisList(newArray);
    const newSelectedKPIs = newArray
      .map((v) => v.kpiData)
      .flat()
      .filter((v) => v.checked)
      .map((v, index) => ({ ...v, index: v.index || index }));
    setSelectedKPIs(newSelectedKPIs);
  };

  const steps = [
    {
      title: "General information",
      component: (
        <GeneralInformation form={form} onChangeData={onChangeFieldsData} />
      ),
    },
    {
      title: "KPIs selection",
      component: (
        <SelectKPI
          dataSheetsWithKpi={kpisList}
          onChangeSelectionKPIs={handleChangeSelectionKPIs}
        />
      ),
    },
    {
      title: "EmailBody",
      component: (
        <EmailTemplate form={form} onChangeEmailBody={onChangeFieldsData} />
      ),
    },
    {
      title: "Survey order",
      component: (
        <SurveyOrder
          onSortSurveyOrder={onSortSurveyOrder}
          selectedKPIs={selectedKPIs}
          surveyData={surveyData}
        />
      ),
    },
    {
      title: "Success",
      component: <Success action={action} survey={surveyData} />,
    },
  ];

  const onStepChange = async (selectedStep: number) => {
    try {
      if (step <= selectedStep) {
        await form.validateFields();
      }
      const stepForSteps = selectedStep + 1;
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const isDisabledNext = (step === 2 || step === 4) && !selectedKPIs?.length;

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadingKPIs}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                className="margin-5"
                type="custom"
                onClick={() => navigate("/management/collect-data")}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>
            <Col
              className="user-flow-steps-wrap color-white"
              lg={23}
              md={24}
              sm={24}
              xs={24}
            >
              <StyledSteps current={step - 1} onChange={onStepChange}>
                {steps
                  ?.filter((v) => v.title !== "Success")
                  .map((stepData, index) => (
                    <StyledSteps.Step
                      className="stepper-wrapper"
                      key={index + 1}
                      title={stepData.title}
                    />
                  ))}
              </StyledSteps>
            </Col>
          </Row>
          <Progress
            percent={step * (100 / steps?.length)}
            strokeColor={"#A68DFB"}
            showInfo={false}
          />

          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={surveyData}
                  onFieldsChange={onChangeFieldsData}
                >
                  <FormWrap width={step !== 1 && "60%"}>
                    <FormWrapper>
                      {steps[step - 1].component}
                      {steps[step - 1].title !== "Success" && (
                        <Form.Item>
                          <FooterButtons
                            step={step}
                            surveyId={surveyId}
                            onClickBack={onClickBack}
                            onClickNext={onClickNext}
                            disabled={isDisabledNext}
                          />
                        </Form.Item>
                      )}
                    </FormWrapper>
                  </FormWrap>
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};
