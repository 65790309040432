import { encodeUrlName } from "../../shared/commonFunctions";

export const fractionalNumber = (companyDecimalNumber: number = 6) => {
  return (value: number, toFixed?: number) => {
    if (
      value?.toString().split(".")[1]?.length >
      (toFixed || companyDecimalNumber)
    ) {
      return +Number(value.toString().split("e")[0]).toFixed(
        toFixed || companyDecimalNumber
      );
    }
    return +value;
  };
};

export const getScopeNumber = (value: string) => {
  switch (value) {
    case ScopeName.SCOPE_1:
      return "1";
    case ScopeName.SCOPE_2:
      return "2";
    case ScopeName.SCOPE_3:
      return "3";
    default:
      return value;
  }
};

export const mergeArraysByKeys = (
  baseArray: Emission[],
  newArray: Emission[]
) => {
  const newArrayKeys = newArray.map((el) => el.key);
  return [
    ...baseArray.filter((el) => !newArrayKeys.includes(el.key)),
    ...newArray,
  ];
};

export const mergeArraysByKeysOffsets = (
  baseArray: Offset[],
  newArray: Offset[]
) => {
  const newArrayKeys = newArray.map((el) => el.key);
  return [
    ...baseArray.filter((el) => !newArrayKeys.includes(el.key)),
    ...newArray,
  ];
};

export const getLinkDataSheetSimple = (
  elem: AutomatedEmission,
  sheet_name: string,
  drillDownRecord?: DrillDownRecord
) => {
  const url = `/data/data-sheets/${elem.activity_sheet}/${encodeUrlName(
    sheet_name.replace("/", " ")
  )}/documents`;

  const filtersArray = elem.filters
    ? elem.filters?.map((item) =>
        item.op === "isEmpty" && item.column_value === false
          ? { ...item, op: "isNotEmpty", column_value: true }
          : item
      )
    : [];
  const filters = drillDownRecord
    ? [...filtersArray, ...drillDownRecord?.filter!]
    : filtersArray;
  return { url, filtersArray: filters };
};

export const getLinkDataSheet = (
  elem: AutomatedEmission,
  dataSheet: DataSheet,
  drillDownRecord?: DrillDownRecord
) => {
  const url = `/data/data-sheets/${elem.activity_sheet}/${encodeUrlName(
    dataSheet?.sheet_name?.replace("/", " ")
  )}/documents`;

  const filtersArray = elem.filters
    ? elem.filters?.map((item) =>
        item.op === "isEmpty" && item.column_value === false
          ? { ...item, op: "isNotEmpty", column_value: true }
          : item
      )
    : [];
  const filters = drillDownRecord
    ? [...filtersArray, ...drillDownRecord?.filter!]
    : filtersArray;
  return { url, filtersArray: filters };
};

export const getEmissionsUnit = (unit: string) => {
  switch (unit) {
    case "mt":
      return "MT";
    case "lb":
      return "lb";
    case "t":
      return "T";
    default:
      return "kg";
  }
};

export const removeNullKeys = (obj: { [key: string]: any }) => {
  const newObj: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const disallowedNameKeys = ["/", "?", "&", "=", "#", "+", "%", "\\"];
