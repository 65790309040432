import React from 'react';
import { Checkbox } from 'antd';

const SubmenuItemSelectAll = ({checked, handleSelect}) => {
    return (
        <div style={{ padding: '10px'}}>
            <Checkbox
                checked={checked}
                onChange={(e) => handleSelect(e)}
            >
            </Checkbox>  
            <span style={{ color: 'white', alignItems: 'left'}}>
                <span style={{ color: 'white', paddingLeft: '5px' }}>Select All</span>
            </span>
        </div>
    )
}

export default SubmenuItemSelectAll;